import { start } from 'node:repl'
import type { IItemRewardTier, IPage } from '@/types'

export type ImageReactionType = 'good' | 'neutral' | 'bad' | null

export interface ImageReaction {
	good_react_cnt: number
	neutral_react_cnt: number
	bad_react_cnt: number
	user_react: ImageReactionType
}

export interface IImage extends ImageReaction {
	id: number
	seed: string
	uuid: string
	scene_id: number
	is_favorite: boolean
	rank: IItemRewardTier
	prompt: string
	style_id: number
	token?: boolean
	skeleton?: boolean
}

export enum EImageGalleryType {
	Favorite = 'collection',
	Gallery = 'gallery',
	Event = 'Event',
}
