<script setup lang="ts">
import { computed, defineAsyncComponent, inject, nextTick, onMounted, onUnmounted, ref, shallowRef, watch, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { indexedAccessType } from '@babel/types'
import { useGalleryStore } from '@/stores/galleryStore'
import Tools from '@/utils/tools'
import LockableVariable from '@/utils/lockableVariable'
import { useCompatibilityStore } from '@/stores/compatibilityStore'
import { useSettingStore } from '@/stores/settingStore'
import { useFavoriteStore } from '@/stores/favoriteStore'
import type { IImage, IItemRewardTier, IPage, ImageReaction } from '@/types'
import { DynamicModalTheme, DynamicModalType, EImageGalleryType } from '@/types'
import { useAuthStore } from '@/stores/authStore'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { ImageNotFound, UnauthorizedResource } from '@/errors/FapFapError'
import { useEventGalleryStore } from '@/stores/eventGalleryStore'
import { useAutoScroll } from '@/composables/useAutoScroll'
import { ImageReactionFactory, ImageReactionFactoryFromImage } from '@/types/factory'
import { useSearchStore } from '@/stores/searchStoreV2'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'

// Store is empty in case of a direct link to an image
const props = defineProps<Props>()

const ImageSkeleton = defineAsyncComponent(() => import('@/components/image/ImageSkeleton.vue'))
const ImageMenu = defineAsyncComponent(() => import('@/components/image/ImageMenu.vue'))

interface Props {
	uuid: string
	previousRoute?: string
}

const request = inject<RequestRouter>('request')
const { t } = useI18n()
const authStore = useAuthStore()
const settingStore = useSettingStore()
const searchStore = useSearchStore()
const router = useRouter()
const route = useRoute()
const disableScroll = shallowRef<boolean>(false)
const maxPage = ref<number>(Infinity)
const imageSrc = ref<string>('')
const uuid = ref<string>('')
const reaction = ref<ImageReaction>(ImageReactionFactory())
const rank = ref<IItemRewardTier>('S')
const isFavorite = ref<boolean>(false)
const prompt = ref<string>('')
const styleId = ref<number>(0)
const imageElement = ref<HTMLImageElement>()
const galleryStore = useGalleryStore()
const favoriteStore = useFavoriteStore()
const bundleStore = useEventGalleryStore()
const compat = useCompatibilityStore()
const { seed, timestamp, galleryType, imageFullScreen, restoreScroll } = storeToRefs(settingStore)
const { user } = storeToRefs(authStore)
const { fullscreenScroll, stop: stopAutoScroll } = useAutoScroll()
const img = new Image()
const loading = ref(true)
const loadingMoreImages = ref(true)
const scrollToOnLeave = ref<null | (() => void)>(null)

const { toDelete } = storeToRefs(favoriteStore)
const markForDeletion = ref<boolean>(toDelete.value.includes(props.uuid))

let pageOffset: number = 1
let imageStore: typeof galleryStore | typeof favoriteStore | typeof bundleStore = galleryStore

if (galleryType.value === EImageGalleryType.Favorite) {
	imageStore = favoriteStore
}

if (galleryType.value === EImageGalleryType.Gallery) {
	imageStore = galleryStore
}

if (galleryType.value === EImageGalleryType.Event) {
	imageStore = bundleStore
}

const { images } = storeToRefs(imageStore)
let directLink = !images.value.length

let scroll = 0

const dynamicModalStore = useDynamicModalStore()

const { hidden } = storeToRefs(dynamicModalStore)

watchEffect(() => {
	if (!hidden.value) {
		scroll = 0
	}
})
function openAd() {
	scroll++
	if (scroll === 12 && !authStore.isAuth()) {
		dynamicModalStore.showModal(DynamicModalType.Login, {
			props: { popup: true },
			theme: DynamicModalTheme.Borderless,
		})
	}
}

const loadNextImage = {
	ArrowDown: () => {
		openAd()
		if (imageStore.index + 1 < maxPage.value) {
			loading.value = true
			imageStore.index++
		}
		else {
			stopAutoScroll()
		}
	},
	ArrowUp: () => {
		openAd()
		if (imageStore.index > 0) {
			loading.value = true
			imageStore.index--
		}
	},

}

function handleKeydown(event: KeyboardEvent) {
	if (event.repeat) {
		return
	}
	if (event.key === 'Escape') {
		unmountComponent()
	}
	if (imageFullScreen.value && (
		event.key === 'ArrowDown'
		|| event.key === 'ArrowUp'
		|| event.key === 'PageDown'
		|| event.key === 'PageUp'
		|| event.key === ' '
	)) {
		event.preventDefault()
	}

	if (imageFullScreen.value && (
		event.key === 'ArrowDown'
		|| event.key === 'ArrowUp')) {
		loadNextImage[event.key]()
	}
}

function handleWheel(event: WheelEvent) {
	if (disableScroll.value) {
		return
	}
	if (imageFullScreen.value) {
		event.preventDefault()
		const dir = event.deltaY > 0 ? 'ArrowDown' : 'ArrowUp'

		loadNextImage[dir]()
	}
}

async function setImageInfo(): Promise<void> {
	try {
		let imageData = await request?.exec(RequestAction.GetImageInfo, {
			routeParams: {
				uuid: uuid.value,
			},
		})
		if (imageData) {
			imageData = imageData as IImage
			if ('is_favorite' in imageData) {
				isFavorite.value = imageData.is_favorite
			}
			if ('prompt' in imageData) {
				prompt.value = imageData.prompt
			}
			if ('style_id' in imageData) {
				styleId.value = imageData.style_id
			}
			if ('rank' in imageData) {
				rank.value = imageData.rank
			}
			reaction.value = ImageReactionFactoryFromImage(imageData)
		}
		else {
			router.back()
		}
	}
	catch (error: any) {
		if (error instanceof UnauthorizedResource) {
			loading.value = false
		}
		if (error instanceof ImageNotFound) {
			imageFullScreen.value = false
			router.push('/404')
		}
	}
}

function registerScrollOnLeave(): void {
	scrollToOnLeave.value = () => {
		nextTick(() => {
			setTimeout(() => {
				const target = document.getElementById(uuid.value)
				if (target) {
					target.scrollIntoView({ block: 'center' })
					target.dispatchEvent(
						new CustomEvent('highlight'),
					)
				}
			}, 200)
		})
	}
}

let reachEnd = false

async function loadImageFromStore(index: number, fetchNewContent: boolean = false) {
	if (!loadingMoreImages.value) {
		return
	}
	const length = images.value.length

	if (index > images.value.length - 4 && fetchNewContent && 'loadMoreImages' in imageStore) {
		loadingMoreImages.value = false
		console.log('preload')
		if (!reachEnd) {
			await imageStore?.loadMoreImages()
		}
		nextTick(() => {
			if (images.value.length === length) {
				reachEnd = true
			}
			loadingMoreImages.value = true
			loadImageFromStore(index)
			registerScrollOnLeave()
		})
	}
	else if (index < images.value.length) {
		const image = images.value[index]
		uuid.value = image.uuid
		isFavorite.value = image.is_favorite
		styleId.value = image.style_id
		prompt.value = image.prompt
		rank.value = image.rank
		reaction.value = ImageReactionFactoryFromImage(image)
		router.replace({
			path: `/image/${uuid.value}`,
			query: { ...route.query },
		})
		downloadImage(uuid.value)
		registerScrollOnLeave()
	}
	else {
		imageStore.index--
	}
}

watch(() => imageStore.index, async (index) => {
	if (directLink && !index) {
		directLink = false
		uuid.value = props.uuid ?? ''
		await setImageInfo()
		downloadImage(uuid.value)
		router.replace({ path: `/image/${uuid.value}` })
	}
	else {
		loadImageFromStore(index, galleryType.value !== EImageGalleryType.Event)
	}
})

async function handleDirectLink() {
	await setImageInfo()
	pageOffset = Tools.random(1, await getImageCount())
	imageStore.index = 0
}

onMounted(async () => {
	Tools.toggleScrollbar(false)
	fullscreenScroll.value = true
	imageFullScreen.value = true
	window.addEventListener('keydown', handleKeydown)
	uuid.value = props.uuid ?? ''

	if (!uuid.value.length) {
		fullscreenScroll.value = false
		imageFullScreen.value = false
		return
	}

	if (directLink) {
		await handleDirectLink()
	}
	else {
		try {
		// enter this condition after clicking on an image from the main gallery or favorites
			isFavorite.value = images.value[imageStore.index].is_favorite
			styleId.value = images.value[imageStore.index].style_id
			prompt.value = images.value[imageStore.index].prompt
			rank.value = images.value[imageStore.index].rank
			reaction.value = ImageReactionFactoryFromImage(images.value[imageStore.index])
			downloadImage(props.uuid ?? '')
			router.push({
				path: `/image/${uuid.value}`,
				query: { ...route.query },
			})
		}
		catch {
			directLink = true
			await handleDirectLink()
		}
	}
})

function unmountComponent() {
	imageFullScreen.value = false

	if (!directLink) {
		router.replace({
			path: galleryType.value === EImageGalleryType.Gallery ? '/' : '/collection',
			query: { ...route.query },
		}).then(() => {
			if (scrollToOnLeave.value) {
				scrollToOnLeave.value()
			}
			else {
				restoreScroll.value()
			}
		})
	}
	else {
		router.push('/')
	}
}

onUnmounted(() => {
	Tools.toggleScrollbar(true)
	fullscreenScroll.value = false
	imageFullScreen.value = false
	window.removeEventListener('keydown', handleKeydown)

	if (scrollToOnLeave.value) {
		scrollToOnLeave.value()
	}
	else {
		restoreScroll.value()
	}
})

let v: number[] = [0, 0]
let dir: number[] = [0, 0]
const touchIdentifier = new LockableVariable<number>(0)

function resetImagePosition() {
	if (imageElement.value) {
		imageElement.value.style.transform = `translate(0px, 0px)`
		imageElement.value.style.width = ''
		imageElement.value.style.opacity = ''
	}
	v = [0, 0]
	dir = [0, 0]
}

function touchStart(event: TouchEvent) {
	if (disableScroll.value) {
		return
	}
	if (imageFullScreen.value && touchIdentifier.isUnlocked()) {
		touchIdentifier.setValue(event.touches.item(0)?.identifier ?? 0).lock()

		resetImagePosition()
		v = [
			event.touches[0].clientX,
			event.touches[0].clientY,
		]
	}
}

function handleTouchMove(event: TouchEvent) {
	if (disableScroll.value) {
		return
	}
	event.preventDefault()

	let found = false

	for (let i = 0; i < event.touches.length; i++) {
		const touch: Touch = event.touches[i]

		if (touch.identifier === touchIdentifier.getValue()) {
			found = true
			break
		}
	}
	if (imageFullScreen.value && found && touchIdentifier.isLocked()) {
		const v2 = [
			event.touches[0].clientX,
			event.touches[0].clientY,
		]

		dir = [
			Math.ceil(v2[0] - v[0]),
			Math.ceil(v2[1] - v[1]),
		]
		const length = Math.sqrt(dir[0] ** 2 + dir[1] ** 2)

		if (imageElement.value) {
			imageElement.value.style.transform = `translate(${dir[0]}px, ${dir[1]}px)`
			imageElement.value.style.width = `${Tools.map(length, 0, 500, 100, 0)}%`

			if (length >= 30) {
				imageElement.value.style.opacity = `${Tools.map(length, 30, 100, 1, 0)}`
			}
			else {
				imageElement.value.style.opacity = ''
			}
		}
	}
}

function touchEnd(event: TouchEvent) {
	if (disableScroll.value) {
		return
	}
	let found = false

	for (let i = 0; i < event.changedTouches.length; i++) {
		const touch: Touch = event.changedTouches[i]

		if (touch.identifier === touchIdentifier.getValue()) {
			found = true
			break
		}
	}

	if (v[0] !== 0 && found) {
		const length = Math.sqrt(dir[0] ** 2 + dir[1] ** 2)

		if (length >= 100) {
			if (dir[1] > 0) {
				loadNextImage.ArrowUp()
			}
			else {
				loadNextImage.ArrowDown()
			}
		}
		resetImagePosition()
		touchIdentifier.unlock()
	}
}

function disableFullscreenScroll(value: boolean): void {
	disableScroll.value = value
}

function downloadImage(imgSrc: string) {
	imageSrc.value = `${import.meta.env.VITE_CLOUDFLARE_URL}/${imgSrc}.webp`

	img.onload = () => {
		loading.value = false
	}

	img.onerror = () => {
		loading.value = false
	}

	img.src = imageSrc.value
}

async function getImageCount(): Promise<number> {
	const data = await request?.exec(RequestAction.PromptSearch, {
		env: {
			headers: {
				Seed: seed.value.toString(),
			},
		},
		routeParams: {
			page: 1,
			size: 1,
			route: EImageGalleryType.Gallery,
		},
		body: searchStore.searchBody,
	})
	if (data) {
		return data.total
	}
	return 0
}
</script>

<template>
	<div
		v-if="imageFullScreen" id="container" @wheel="handleWheel" @touchstart="touchStart"
		@touchmove="handleTouchMove" @touchend="touchEnd"
	>
		<Button
			icon="pi pi-times" rounded text aria-label="Close" severity="help"
			class="sm:top-[10px] sm:right-[10px] top-[4px] right-0 z-20 absolute bg-transparent hover:bg-orange-400 flex-center border-none rounded-full cursor-pointer [&>*]:hover:text-black"
			size="large" @click.stop.prevent="unmountComponent" @touchend.stop.prevent="unmountComponent"
		/>

		<div class="image-container">
			<ImageSkeleton v-if="loading" type="fullscreen" />
			<img
				v-else
				ref="imageElement"
				:src="imageSrc"
				alt="Zoomed Image"
				class="w-full h-auto max-h-full object-contain z-[14]"
				:class="markForDeletion ? 'opacity-50' : 'opacity-100'"
				@load="loading = false"
			>
		</div>

		<div
			v-if="!compat.mobileDevice"
			class="md:flex hidden flex-col justify-center items-center fixed right-[10px] top-1/2 transform -translate-y-1/2 rounded-[6px] w-12 bg-slate-800 overflow-hidden z-[15] border-2 border-slate-700 border-solid"
		>
			<span
				class="w-full flex justify-center items-center h-12 hover:bg-orange-500 [&_i]:hover:text-black cursor-pointer transform duration-300"
				@click="loadNextImage.ArrowUp"
			>
				<i class="pi pi-chevron-up text-base md:text-lg" size="large" @touchend="loadNextImage.ArrowUp" />
			</span>
			<span class="w-full h-[2px] bg-slate-700" />
			<span
				class="w-full flex justify-center items-center h-12 hover:bg-orange-500 [&_i]:hover:text-black cursor-pointer transform duration-300"
				@click="loadNextImage.ArrowDown"
			>
				<i class="pi pi-chevron-down text-base md:text-lg" @touchend="loadNextImage.ArrowDown" />
			</span>
		</div>
		<ImageMenu
			v-model:isFavorite="isFavorite"
			:reaction="reaction"
			:prompt="prompt"
			:style-id="styleId"
			:rank="rank"
			:index="imageStore.index"
			:uuid="uuid"
			:show-menu="true"
			class-menu="image-zoomed-menu"
			:vertical="true"
			:disable-fullscreen-scroll="disableFullscreenScroll"
			@next-image="loadNextImage.ArrowDown"
			@update:mark-for-deletion="(state: boolean) => markForDeletion = state"
			@exit-full-screen="unmountComponent"
		/>
	</div>
</template>

<style>
.image-zoomed-menu {
	@apply bottom-0 right-0 z-[14];
}
</style>

<style scoped lang="scss">
#container {
	@apply fixed flex justify-center items-center flex-col top-0 left-0 w-screen h-[100dvh] z-[13] bg-slate-950;
}

.image-container {
	@apply w-screen h-[100dvh] flex justify-center items-center overflow-hidden;
}
</style>
