import { defineStore } from 'pinia'
import type { ComponentOptions } from 'vue'
import { computed, defineAsyncComponent, markRaw, reactive, ref, shallowRef } from 'vue'
import type { LucideIcon } from 'lucide-vue-next'
import type { ComponentRegistration, MountedComponent } from '@/types'
import { DynamicModalTheme, DynamicModalType } from '@/types'

export const useDynamicModalStore = defineStore('DynamicModalStore', () => {
	const componentRegistry: Partial<Record<DynamicModalType, ComponentRegistration>> = {}
	const activeComponents = reactive<MountedComponent[]>([])
	const activeIndex = ref<number>(-1)
	const indexHistory = ref<number[]>([])
	const activeProps = ref<Record<string, any>[]>([])
	const hidden = shallowRef<boolean>(true)
	const activeModal = ref<DynamicModalType>(DynamicModalType.Default)

	const title = computed(() => {
		const activeTitle = activeComponents[activeIndex.value]?.data.title
		return typeof activeTitle === 'function' ? activeTitle() : activeTitle || ''
	})
	const subtitle = computed(() => activeComponents[activeIndex.value].data.subtitle)
	const icon = computed(() => activeComponents[activeIndex.value].data.icon)
	const theme = computed(() => activeComponents[activeIndex.value].data.theme)
	const blockBodyScroll = computed(() => activeComponents[activeIndex.value].data.blockBodyScroll)
	const shortcuts = computed(() => activeComponents[activeIndex.value].data.shortcuts)

	/**
	 * Registers components dynamically in the registry.
	 */
	function registerComponents(components: ComponentRegistration[]) {
		components.forEach((componentRegistration: ComponentRegistration) => {
			const { importCb, data, type } = componentRegistration
			componentRegistry[type] = {
				importCb: defineAsyncComponent(importCb),
				type,
				data: {
					props: data?.props ?? {},
					title: data?.title ?? '',
					subtitle: data?.subtitle ?? '',
					icon: data?.icon,
					theme: data?.theme ? data.theme : DynamicModalTheme.Auto,
					blockBodyScroll: typeof data?.blockBodyScroll === 'boolean' ? data.blockBodyScroll : true,
					shortcuts: data?.shortcuts?.length ? data.shortcuts : [],
				},
			}
		})
	}

	/**
	 * Sets the active component and its props.
	 */
	function showModal(type: DynamicModalType, options: ComponentOptions = {}) {
		if (componentRegistry[type]) {
			activeModal.value = type
			mountComponent()
			void (options.title ? updateTitle(options.title) : null)
			void (options.subtitle ? updateSubtitle(options.subtitle) : null)
			void (options.props ? updateProps(options.props) : null)
			void (options.icon ? updateIcon(options.icon) : null)
			void (options.theme ? updateTheme(options.theme) : null)
			if (blockBodyScroll.value) {
				document.body.classList.add('dynamic-modal-overflow-hidden')
			}
		}
		else {
			console.error(`Component "${type}" is not registered.`)
		}
	}

	/**
	 * Restores the previous component in the stack.
	 */
	function restorePreviousComponent(): void {
		if (activeIndex.value > 0 && indexHistory.value.length >= 2) {
			activeIndex.value = indexHistory.value[indexHistory.value.length - 2]
			indexHistory.value.pop()
			activeModal.value = activeComponents[activeIndex.value].type ?? DynamicModalType.Default
		}
	}

	/**
	 * Closes the modal and resets the state.
	 */
	function closeModal() {
		document.body.classList.remove('dynamic-modal-overflow-hidden')
		activeIndex.value = -1
		hidden.value = true
		activeComponents.length = 0
		activeProps.value = []
		indexHistory.value = []
	}

	function updateTitle(title: string | (() => string)): void {
		const activeTitle = activeComponents[activeIndex.value]?.data.title
		activeComponents[activeIndex.value].data.title
			= typeof title === 'function' ? title() : title || activeTitle || ''
	}

	function updateProps(props: Record<string, any>): void {
		activeProps.value[activeIndex.value] = {
			...activeProps.value[activeIndex.value],
			...props,
		}
	}

	function updateSubtitle(subtitle: string): void {
		activeComponents[activeIndex.value].data.subtitle = subtitle
	}

	function updateIcon(icon: LucideIcon): void {
		activeComponents[activeIndex.value].data.icon = icon
	}

	function updateTheme(theme: DynamicModalTheme): void {
		activeComponents[activeIndex.value].data.theme = theme
	}

	function mountComponent() {
		// Find if the component is already mounted
		const mountedIndex = activeComponents.findIndex(({ type }) => type === activeModal.value)
		if (!(activeModal.value in componentRegistry)) {
			return
		}

		if (mountedIndex === -1) {
			// Add new component to activeComponents if not already mounted
			if (!componentRegistry[activeModal.value]) {
				console.error(`Component "${activeModal.value}" is not registered.`)
				return
			}

			const componentRegistration = componentRegistry[activeModal.value]
			if (componentRegistration) {
				activeComponents.push({
					component: markRaw(componentRegistration.importCb),
					type: componentRegistration.type ?? DynamicModalType.Default,
					data: { ...componentRegistration.data },
				})
			}
			activeIndex.value = activeComponents.length - 1
			indexHistory.value.push(activeIndex.value)
			activeProps.value.push(componentRegistry[activeModal.value]?.data?.props ?? {})
		}
		else {
			// Reuse the existing component
			activeIndex.value = mountedIndex
			indexHistory.value.push(activeIndex.value)
		}

		hidden.value = false
	}

	return {
		title,
		subtitle,
		icon,
		activeComponents,
		activeProps,
		activeIndex,
		hidden,
		theme,
		blockBodyScroll,
		shortcuts,
		updateTitle,
		updateProps,
		updateSubtitle,
		updateIcon,
		updateForceFullscreen: updateTheme,
		registerComponents,
		showModal,
		closeModal,
		restorePreviousComponent,
	}
})
