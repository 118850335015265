<script setup lang="ts">
import { addYears } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { defineAsyncComponent, ref, watch } from 'vue'
import router from '@/router'
import Tools from '@/utils/tools'
import { useSettingStore } from '@/stores/settingStore'
import type { ILocaleCode } from '@/types'

const LocaleSetter = defineAsyncComponent(() => import('@/components/locale_setter/DropdownLocaleSetter.vue'))
const settingsStore = useSettingStore()
const locale = ref<ILocaleCode>(settingsStore.getLocale().code)

const { t } = useI18n()

function accept() {
	const redirectUrl = localStorage.getItem('redirectUrl')
	Tools.setCookie('age-verification', 'true', addYears(new Date(), 1), `.${import.meta.env.VITE_DOMAIN}`, '/')
	if (redirectUrl) {
		localStorage.removeItem('redirectUrl')
		router.push(redirectUrl)
	}
}

watch(() => locale.value, async (code: ILocaleCode) => {
	Tools.setCookie('lang', code, addYears(new Date(), 1), `.${import.meta.env.VITE_DOMAIN}`, '/')
})
</script>

<template>
	<div class="fixed flex w-screen h-[100dvh] justify-center items-center bg-slate-950 z-[7]">
		<section class="flex flex-col sm:w-[53rem] w-full px-4 gap-4 h-fit items-center">
			<div class="w-fit">
				<LocaleSetter v-model="locale" class="w-full" />
			</div>
			<article class="bg-slate-800 flex w-full flex-wrap h-fit p-3 rounded justify-center border-slate-500 border-2 border-solid">
				<h2
					class="md:text-nowrap w-full text-center m-0 text-h4 md:text-h3 lg:text-h1"
				>
					{{ t('age_verification.title') }}
				</h2>
				<p class="text-center text-sm md:text-base">
					{{ t('age_verification.paragraph1') }}
				</p>
				<p class="text-center text-sm md:text-base">
					{{ t('age_verification.paragraph2') }}
				</p>
				<div class="flex items-center gap-4 sm:gap-2 w-full flex-col sm:flex-row">
					<router-link to="/" class="no-underline w-full sm:w-8/12" @click.prevent="accept">
						<Button :label="t('age_verification.button.over_18')" class="w-full" />
					</router-link>
					<a href="https://google.com" class="w-full sm:grow">
						<Button outlined :label="t('age_verification.button.leave_website')" class="w-full" />
					</a>
				</div>
			</article>
		</section>
	</div>
</template>

<style scoped lang="scss"></style>

<style></style>
