import { defineStore } from 'pinia'
import { ref, shallowRef } from 'vue'

export const useCompatibilityStore = defineStore('compatibilityStore', () => {
	const mobileDevice = ref<boolean>(navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|mobile|Tablet/i) !== null)
	const landscape = shallowRef<boolean>(!window.matchMedia('(orientation: portrait)').matches)

	window.matchMedia('(orientation: portrait)').addEventListener('change', (e) => {
		landscape.value = !e.matches
	})

	return { mobileDevice, landscape }
})
