import { defineStore, storeToRefs } from 'pinia'
import { inject, ref, shallowRef, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useSettingStore } from './settingStore'
import { useSearchStore } from './searchStoreV2'
import { useDynamicModalStore } from './dynamicModalStore'
import { useAuthStore } from './authStore'
import { DynamicModalTheme, DynamicModalType, EImageGalleryType, type IImage } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'

export const useGalleryStore = defineStore('gallery images', () => {
	const request = inject<RequestRouter>('request')
	const settingStore = useSettingStore()
	const searchStore = useSearchStore()

	const { seed, timestamp } = storeToRefs(settingStore)
	const { searchBody } = storeToRefs(searchStore)

	const images = ref<Array<IImage>>([])

	const index = ref<number>(-1)
	const page = ref<number>(0)
	const size = 12

	const reachEnd = shallowRef(false)

	const loadMoreImages = async (): Promise<void> => {
		if (reachEnd.value) {
			return
		}
		if (!useAuthStore().isAuth() && page.value >= 4 && page.value % 2 === 0) {
			useDynamicModalStore().showModal(DynamicModalType.Login, {
				props: { popup: true },
				theme: DynamicModalTheme.Borderless,
			})
		}

		const headers = new Headers()
		headers.append('Seed', seed.value.toString())
		headers.append('Timestamp', timestamp.value)

		const fetchedImages = await request?.exec(RequestAction.PromptSearch, {
			env: {
				headers,
			},
			routeParams: {
				page: ++page.value,
				size,
				route: EImageGalleryType.Gallery,
			},
			body: searchBody.value,
		})
		if (fetchedImages && fetchedImages.items.length) {
			fetchedImages.items.forEach((image) => {
				image.skeleton = true
			})

			images.value = [
				...images.value,
				...fetchedImages.items,
			]
		}
		else {
			reachEnd.value = true
		}
	}

	watch(() => page.value, (newPage, oldPage) => {
		if (newPage < oldPage) {
			reachEnd.value = false
		}
	})

	const setZoomedImage = async (_index: number) => {
		if (_index < 0) {
			throw new Error('Index .')
		}
		else {
			index.value = _index
		}
	}

	const updateImage = (uuid: string, content: Partial<IImage>): void => {
		const i = images.value.findIndex(image => image.uuid === uuid)

		if (i >= 0) {
			images.value[i] = {
				...images.value[i],
				...content,
			}
		}
	}

	const getImage = (uuid: string): IImage | null => {
		const i = images.value.findIndex(image => image.uuid === uuid)

		return i >= 0 ? images.value[i] : null
	}

	return {
		images,
		page,
		size,
		index,
		loadMoreImages,
		setZoomedImage,
		updateImage,
		getImage,
	}
})
