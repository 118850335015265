import setCloudflareTokenMiddleware from '../middlewares/setCloudflareToken'
import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import type { GraphicStyle } from '@/types'

const getB2Token: ApiRequest<string> = {
	action: RequestAction.GetB2Token,
	endpoint: 'b2_token',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		return (await response.json()).token
	},
}

const setCloudflareToken: ApiRequest<unknown> = {
	action: RequestAction.SetCloudflareToken,
	endpoint: 'token',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		const { token } = await response.json()

		if (navigator.serviceWorker.controller) {
			navigator.serviceWorker.controller.postMessage({
				type: 'SET_AUTH_TOKEN',
				token,
			})
		}
	},
}

const getGraphicStyles: ApiRequest<GraphicStyle[]> = {
	action: RequestAction.GetGraphicStyles,
	endpoint: 'styles',
	middlewares: [/* setCloudflareTokenMiddleware */],
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		return await response.json()
	},
}

export default {
	prefix: '/api/config',
	middlewares: [],
	queries: [getB2Token, setCloudflareToken, getGraphicStyles],
} as RequestRoutes
