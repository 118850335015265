import type { LucideIcon } from 'lucide-vue-next'
import type { defineAsyncComponent } from 'vue'

/**
 * Represents the different types of dynamic modals.
 */
export enum DynamicModalType {
	Default = '',
	Login = 'login',
	RegisterSuccess = 'register_success',
	ForgotPassword = 'forgot_password',
	Settings = 'settings',
	LockedFeature = 'locked_feature',
	CheckoutPremium = 'checkout_premium',
	PurchaseHistory = 'purchases_history',
	DeleteCollection = 'delete_collection',
	Notification = 'notification',
	AvatarEdit = 'avatar_edit',
	MuteEvent = 'mute_event',
	Community = 'community',
	MyPrompts = 'my_searches',
	SelectStyles = 'select_styles',
	Subscription = 'subscription',
	Menu = 'Menu',
	UpdateLocale = 'update_locale',
}

export type ComponentType = ReturnType<typeof defineAsyncComponent>

export interface DynamicModalShortcut {
	label: (() => string) | string
	desktop: (() => string) | string
	mobile: (() => string) | string
}

export interface ComponentOptions {
	props?: Record<string, any>
	title?: string | (() => string)
	subtitle?: string | (() => string)
	icon?: LucideIcon | null
	// default to DynamicModalTheme.Auto
	theme?: DynamicModalTheme
	blockBodyScroll?: boolean
	shortcuts?: DynamicModalShortcut[]
}
export interface ComponentRegistration {
	importCb: () => Promise<any>
	type: DynamicModalType
	data?: ComponentOptions
}

export interface MountedComponent {
	component: ComponentType
	type: DynamicModalType
	data: ComponentOptions
}

export enum DynamicModalTheme {
	Auto = '',
	Borderless = '-borderless',
	Fullscreen = '-fs',
	Large = '-large',
}
