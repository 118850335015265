<script setup lang="ts">
import { computed, defineAsyncComponent, inject, onBeforeUnmount, onMounted, onUnmounted, ref, shallowRef, watch, watchEffect } from 'vue'
import { Box, Images, LibraryBigIcon, Medal, Search, SearchCheck, Sword, User, WandSparkles } from 'lucide-vue-next'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { addYears } from 'date-fns'
import Menu from 'primevue/menu'
import type { LucideIcon } from 'lucide-vue-next'
import { useSettingStore } from '@/stores/settingStore'
import { DynamicModalType, type ILocaleCode } from '@/types'
import Tools from '@/utils/tools'
import { useAuthStore } from '@/stores/authStore'
import router from '@/router'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import { useFavorites } from '@/composables/useFavorites'
import { useResizeListener } from '@/composables/useResizeListener'
import { useGameStore } from '@/stores/gameStore'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { useSearchStore } from '@/stores/searchStoreV2'
import { useCompatibilityStore } from '@/stores/compatibilityStore'

const LocaleSetter = defineAsyncComponent(() => import('@/components/locale_setter/DropdownLocaleSetter.vue'))
const NotificationBell = defineAsyncComponent(() => import('@/components/notification/NotificationBell.vue'))
const Avatar = defineAsyncComponent(() => import('@/components/game/Avatar.vue'))
const MenuXpBar = defineAsyncComponent(() => import('@/components/game/MenuXpBar.vue'))

const request = inject<RequestRouter>('request')
const { t } = useI18n()
const menu = ref()
const button_collection = ref()
const notification = ref()
const assets = ref<string>(import.meta.env.VITE_STATIC_ASSETS)
const settingsStore = useSettingStore()
const compat = useCompatibilityStore()
const gameStore = useGameStore()
const authStore = useAuthStore()
const dynamicModalStore = useDynamicModalStore()
const { routeToFavorites } = useFavorites()
const searchStore = useSearchStore()
const { openPanel, emptyPrompts } = storeToRefs(searchStore)
const { user } = storeToRefs(authStore)
const { mobileDevice } = storeToRefs(compat)
const { userEquippedItems } = storeToRefs(gameStore)
const { imageFullScreen } = storeToRefs(settingsStore)
const { computedScreenWidth, computedScreenHeight } = useResizeListener()

const discordLink = ref<string>(import.meta.env.VITE_DISCORD_INVITE_LINK ?? '')
const locale = ref<ILocaleCode>(settingsStore.getLocale().code)
const { scrollDirection, scrollPosition } = useScrollDirectionEvent()

const route = useRoute()
const showFilterBtn = ref<boolean>(false)
const imageCount = ref<number>(0)
showFilterBtn.value = route.path === '/'

request?.exec(RequestAction.GetImageCount).then((count: number) => {
	imageCount.value = count
})

watch(
	() => route.fullPath,
	() => {
		showFilterBtn.value = route.path === '/'
	},
)

const isMobile = computed(() => {
	return computedScreenWidth.value < 1024 || computedScreenHeight.value < 600 || mobileDevice.value
})

watch(() => locale.value, async (code: ILocaleCode) => {
	if (authStore.isAuth()) {
		await authStore.updateUser({ user: { lang: code } })
	}
	Tools.setCookie('lang', code, addYears(new Date(), 1), `.${import.meta.env.VITE_DOMAIN}`, '/')
	window.location.replace(`${import.meta.env.VITE_PROTOCOL}://${code}.${import.meta.env.VITE_DOMAIN}${router.currentRoute.value.path}`)
})

function resizeCb() {
	menu.value?.hide()
}

window.addEventListener('resize', resizeCb)

onBeforeUnmount(() => {
	window.removeEventListener('resize', resizeCb)
})

watchEffect(() => {
	if (scrollPosition.value) {
		menu.value?.hide()
	}
})

async function logout() {
	await authStore.logout()
	dynamicModalStore.closeModal()
	router.push({
		path: '/',
		query: route.query,
	})
}

interface MenuItem {
	label: string // The label text
	icon?: string | LucideIcon // The icon name or component
	showWhenAuth?: boolean // Whether the item should be shown based on authentication status
	action?: () => void // An optional action to perform when the item is clicked
	externalLink?: string // An external link if the item is a link
	route?: string // The route to navigate to if the item is a navigation link
}

interface MenuGroup {
	label: string // The main label text for the group
	items: MenuItem[] // An array of sub-items
}

const items = computed<MenuGroup[]>(() => {
	const isAuthenticated = authStore.isAuth()

	const items: MenuGroup[] = [
		{
			label: user.value.username.length ? user.value.username : t('menu.profile'),
			items: [
				{
					label: t('menu.login'),
					icon: 'pi pi-sign-in',
					showWhenAuth: false,
					action: () => dynamicModalStore.showModal(DynamicModalType.Login),
				},
				{
					label: t('menu.settings'),
					icon: 'pi pi-cog',
					showWhenAuth: true,
					action: () => dynamicModalStore.showModal(DynamicModalType.Settings),
				},
				{
					label: t('menu.profile'),
					icon: 'pi pi-user',
					showWhenAuth: true,
					route: `/profile/${user.value.id}/${user.value.username}`,
				},
				{
					label: t('menu.leaderboard'),
					icon: Medal,
					route: `/leaderboard`,
				},
				{
					label: t('menu.achievements'),
					icon: 'pi pi-trophy',
					showWhenAuth: true,
					route: `/profile/${user.value.id}#achievements`,
				},
				{
					label: t('menu.rewards'),
					icon: Sword,
					showWhenAuth: true,
					route: `/profile/${user.value.id}#rewards`,
				},
				/* 				{
					label: t('menu.purchases'),
					icon: ShoppingBag,
					showWhenAuth: true,
					action: () => dynamicModalStore.displayUserModal(DynamicModalType.PurchaseHistory),
				}, */
				{
					label: t('menu.logout'),
					icon: 'pi pi-sign-out',
					showWhenAuth: true,
					action: logout,
				},
			],
		},
		{
			label: t('menu.follow_us'),
			items: [
				{ label: 'Discord', icon: 'pi pi-discord', externalLink: discordLink.value },
				{ label: 'Reddit', icon: 'pi pi-reddit', externalLink: 'https://www.reddit.com/r/FapFapAI/' },
				{ label: 'x', icon: 'pi pi-twitter', externalLink: 'https://x.com/FapFap_ai' },
			],
		},
		{
			label: t('menu.more'),
			items: [
				{ label: t('menu.about'), icon: 'pi pi-info-circle', route: '/about' },
			],
		},
	]

	return items
		.map(item => ({
			...item,
			items: item.items.filter(subItem =>
				'showWhenAuth' in subItem ? subItem.showWhenAuth === isAuthenticated : true,
			),
		}))
		.filter(item => item.items.length > 0)
})

function toggle(event: any) {
	if (isMobile.value) {
		dynamicModalStore.showModal(DynamicModalType.Menu)
	}
	else if (menu.value) {
		menu.value.toggle(event)
	}
}
const playPlusOneAnim = shallowRef<boolean>(false)

let plusOneListener = () => {
	playPlusOneAnim.value = true
	setTimeout(() => {
		playPlusOneAnim.value = false
	}, 1000)
}

onMounted(() => {
	plusOneListener

	= button_collection.value?.$el.addEventListener('+1', plusOneListener)
})

onUnmounted(() => {
	if (button_collection.value?.$el && plusOneListener) {
		button_collection.value.$el.removeEventListener('+1', plusOneListener)
	}
})
</script>

<template>
	<div class="select-none fixed w-screen bg-slate-900 py-1 px-3 flex items-center justify-between h-[50px]" :class="[scrollDirection === 'up' && scrollPosition > 400 ? 'move-up-menu' : 'move-down-menu', imageFullScreen ? 'z-[8]' : 'z-[15]']">
		<div class="flex items-center flex-col sm:flex-row sm:gap-2">
			<h1 class="text-lg xs:text-xl sm:text-2xl font-extrabold select-none m-0">
				<router-link to="/" class="no-underline text-white">
					FapFap<span class="text-orange-500 rounded-md">.ai</span>
				</router-link>
			</h1>
			<span class="text-xs text-slate-300">{{ Tools.formatNumber(imageCount) }} images</span>
		</div>

		<div class="text-sm lg:flex hidden gap-1">
			<Button class="button-rainbow-shade" :disabled="true">
				<template #default>
					<WandSparkles class="lucide-button-icon" />
					<span>{{ t('buttons.create') }}</span>
				</template>
			</Button>
			<div class="flex gap-1">
				<Button class="menu-button" :disabled="!showFilterBtn" @click="openPanel = !openPanel">
					<template #default>
						<Search v-if="emptyPrompts" class="lucide-button-icon" />
						<SearchCheck v-else class="lucide-button-icon text-orange-400" />
						<span>{{ t('buttons.search') }}</span>
					</template>
				</Button>
				<Button :disabled="!showFilterBtn" class="menu-button" outlined @click="useDynamicModalStore().showModal(DynamicModalType.Community)">
					<template #default>
						<Box class="lucide-button-icon" />
						<span>{{ t('common.browse') }}</span>
					</template>
				</Button>

				<router-link to="/" class="navigation-link no-underline menu-button">
					<Images class="lucide-button-icon" />
					<span>{{ t('menu.gallery') }}</span>
				</router-link>
				<router-link id="button-menu-collection" ref="button_collection" to="/collection" class="navigation-link no-underline menu-button relative" @click.prevent="routeToFavorites">
					<LibraryBigIcon class="lucide-button-icon" />
					<span>{{ t('menu.collection') }}</span>
					<span v-if="playPlusOneAnim" class="sliding-plus">+1</span>
				</router-link>
			</div>
		</div>

		<div class="flex gap-3 items-center mr-2">
			<!-- <router-link to="/premium">
					<Button class="py-1 rounded-md text-xs xs:text-sm">
						<template #default>
							<div class=" flex gap-1 items-center">
								<Check v-if="user.premium.active" :size="22" /> {{ user.premium.active ? t('premium.title') : t('menu.go_premium') }}
							</div>p
						</template>
					</Button>
				</router-link> -->
			<div class="w-fit">
				<div v-if="authStore.isAuth()" class="flex gap-2">
					<NotificationBell ref="notification" />
					<MenuXpBar />
					<div class="w-[36px] h-[36px]">
						<Avatar
							:animation="userEquippedItems.animation.data[0]"
							:src="`${assets}image/${userEquippedItems.profile_pic.data}`"
							:color="userEquippedItems.color.data"
							:frame="userEquippedItems.style.data"
							:curve="userEquippedItems.animation.data[1]"
							@click="toggle"
						/>
					</div>
				</div>
				<div v-else class="button-purple-shade !rounded-full p-button-outlined !p-[8px]" @click="toggle">
					<User class="lucide-button-icon" />
				</div>

				<Menu
					v-if="!isMobile" id="overlay-menu-desktop" ref="menu" :model="items as any" :popup="true" class="z-[15]"
					:pt="{
						root: {
							class: 'bg-gradient-to-br from-slate-800 to-slate-950 rounded-none',
						},
						submenuHeader: {
							class: '!bg-transparent',
						},
						menuitem: {
							class: 'text-sm cursor-pointer font-semibold border-[1px] border-solid border-transparent hover:border-[1px] hover:border-solid hover:border-purple-500 hover:rounded hover:from-purple-500 hover:to-purple-700 hover:bg-gradient-to-br',
						},
					}"
				>
					<template #item="{ item, props }">
						<router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
							<a :href="href" v-bind="props.action" @click="navigate">
								<i v-if="typeof item.icon === 'string'" :class="item.icon" />
								<component :is="item.icon" v-else :size="18" />
								<span class="ml-2">{{ item.label }}</span>
							</a>
						</router-link>
						<a v-else-if="item.externalLink" :href="item.externalLink" v-bind="props.action" target="_SEJ">
							<span :class="item.icon" />
							<span class="ml-2">{{ item.label }}</span>
						</a>
						<a v-else :target="item.target" v-bind="props.action" @click="item.action">
							<i v-if="typeof item.icon === 'string'" :class="item.icon" />
							<component :is="item.icon" v-else :size="18" />
							<span class="ml-2">{{ item.label }}</span>
						</a>
					</template>
					<template #end>
						<Divider class="my-2 w-10/12 m-auto" />
						<LocaleSetter v-model="locale" class="w-full !border-none flex !justify-start" />
					</template>
				</Menu>
			</div>
		</div>
		<!-- </div> -->
		<!-- <div class="absolute top-[60px] h-[50px] sm:w-fit w-[95dvw] text-sm p-2 flex justify-between items-center gap-2 rounded-md bg-slate-900/80 left-1/2 -translate-x-1/2 shadow-md shadow-slate-950/80" :class="[scrollDirection === 'up' && scrollPosition > 600 ? 'move-up-menu-motd' : 'move-down-menu-motd']">
			<span class="w-fit">
				We know the issue about xxxx, we're on it.
			</span>
			<div class="flex-center hover:text-white text-slate-400 cursor-pointer h-full">
				<X class="lucide-button-icon-md" />
			</div>
		</div> -->
	</div>
</template>

<style scoped lang="scss">
.logo-title {
	font-family: 'Nova Square', sans-serif;
}

.move-up-menu {
	top: -50px;
	transition: top 0.3s ease-in-out;
}

.move-down-menu {
	top: 0px;
	transition: top 0.3s ease-in-out;
}

.move-up-actions {
	top: -50px;
	transition: top 0.3s ease-in-out;
}

.move-down-actions {
	top: 40px;
	transition: top 0.3s ease-in-out;
}

.move-up-menu-motd {
	top: -50px;
	transition: top 0.3s ease-in-out;
}

.move-down-menu-motd {
	top: 60px;
	transition: top 0.3s ease-in-out;
}
.inner-link {

}
.router-link-active.navigation-link  {
    @apply bg-none bg-slate-500/40;
}

.menu-button {
	@apply bg-none bg-transparent !border-none !outline-none flex items-center justify-center gap-1 min-w-[100px] font-semibold text-sm hover:bg-slate-500/25 duration-300 transition-all
	text-white rounded p-1;
}

.sliding-plus {
	@apply absolute text-orange-400 font-bold;

	animation: slide-in-out 1s ease-in-out forwards;
}

@keyframes slide-in-out {
  0% {
	@apply -right-2 opacity-0 top-1/2 -translate-y-1/2;
  }
  35% {
	@apply -right-4 opacity-100 top-1/2 -translate-y-1/2;
  }
  70% {
	@apply -right-4 opacity-100 top-1/2 -translate-y-1/2;
  }
  100% {
    @apply -right-10 opacity-0 -top-4;
  }
}
</style>
