import { createApp } from 'vue'
import { createPinia } from 'pinia'
import primeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import ConfirmationService from 'primevue/confirmationservice'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import VueMatomo from 'vue-matomo'
import { Bell, Box, ClockArrowDown, Globe, Languages, Lock, MailCheck, Menu, Palette, PencilRuler, Settings, Trash2, User } from 'lucide-vue-next'
import App from './App.vue'
import { i18n } from './i18n'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/aura-dark-pink/theme.css'
import './style.css'
import { useGalleryStore } from './stores/galleryStore'
import router from './router'
import { useDynamicModalStore } from './stores/dynamicModalStore'
import { DynamicModalTheme, DynamicModalType } from './types'
import { useNotificationStore } from '@/stores/notificationStore'
import { useCompatibilityStore } from '@/stores/compatibilityStore'
import { useAudioStore } from '@/stores/audioStore'
import { useSettingStore } from '@/stores/settingStore'
import { useAuthStore } from '@/stores/authStore'
import { useEventGalleryStore } from '@/stores/eventGalleryStore'
import { useCheckoutPremiumStore } from '@/stores/CheckoutPremiumStore'
import metaPlugin from '@/plugins/metaPlugin'
import delayedIntersectDirective from '@/directives/delayedIntersectDirective.ts'
import intersectDirective from '@/directives/intersectDirective'
import intersectOutsideDirective from '@/directives/intersectOutsideDirective'
import focusDirective from '@/directives/focusDirective'
import request from '@/request'

const { t } = i18n.global

async function installServiceWorker(): Promise<void> {
	return new Promise((resolve, reject) => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/service-worker.js', {
				scope: '/',
			}).then(() => {
				resolve()
				if (navigator.serviceWorker.controller) {
					navigator.serviceWorker.controller.postMessage({
						type: 'SET_DOMAIN',
						domain: import.meta.env.VITE_DOMAIN,
					})
				}

				navigator.serviceWorker.addEventListener('message', (event) => {
					// Vérifie que le message contient des données
					if (!event.data || !event.data.type) {
						console.warn('Message mal formé reçu depuis le Service Worker:', event.data)
						return
					}

					switch (event.data.type) {
						case 'AUTH_TOKEN_UPDATED':
							// Log du nouveau token
							// console.log('Nouveau token reçu du Service Worker:', event.data.token)

							// Stocke le token pour une utilisation ultérieure
							localStorage.setItem('authToken', event.data.token)

							// Ajoutez d'autres actions ici si nécessaire
							break

						default:
							console.warn('Type de message inconnu reçu du Service Worker:', event.data.type)
					}
				})
			}).catch((error) => {
				reject(error)
			})
		}
		else {
			reject(new Error('Cannot install service worker'))
		}
	})
}

const app = createApp(App)

app
	.provide('request', request)
	.use(VueMatomo, {
		host: `https://mato.${import.meta.env.VITE_DOMAIN}`,
		siteId: Number(import.meta.env.VITE_MATOMO_SITEID),
		trackerFileName: 'mimim1rm4rjg1o',
	})
	.use(createPinia())
	.use(router)
	.use(metaPlugin, {}, router)
	.use(ConfirmationService)
	.use(primeVue, {
		ripple: false,
		zIndex: {
			modal: 5000,
		},
		pt: {
			Dropdown: {
				root: {
					class: 'text-sm md:text-base relative',
				},
				input: {
					class: 'text-sm md:text-base',
				},
				item: {
					class: 'text-sm md:text-base',
				},
				overlay: {
					class: 'z-[50]',
				},
				emptyMessage: '',
			},
			MultiSelect: {
				input: {
					class: 'text-sm md:text-base',
				},
				root: {
					class: 'text-sm md:text-base relative bg-slate-700 border-slate-600 outline-slate-500',
				},
				label: {
					class: 'text-white',
				},
				token: {
					class: 'bg-orange-500 text-white font-medium',
				},
				panel: {
					class: 'bg-slate-700 border-[1px] border-slate-500',
				},
				item: {
					class: 'text-sm md:text-base bg-slate-700 hover:bg-slate-500',
				},
				itemGroup: {
					class: 'bg-slate-700 first:border-0 border-0 border-t-[1px] border-solid border-slate-500',
				},
				filterInput: {
					class: 'bg-slate-600 border-slate-500 outline-slate-400',
				},
				filterIcon: {
					class: 'text-slate-300',
				},
				closeIcon: {
					class: 'text-white',
				},
				header: {
					class: 'bg-slate-700',
				},
			},
			Calendar: {
				input: {
					class: 'text-sm md:text-base',
				},
				panel: {
					class: 'text-sm md:text-base',
				},
			},
			Button: {
				root: {
					class: 'text-sm md:text-base bg-orange-500 border-orange-400 text-white hover:bg-orange-600',
				},
			},
			Panel: {
				root: {
					class: 'bg-transparent border-none',
				},
				header: {
					class: '!flex !justify-end !flex-row-reverse gap-1 border-solid border-b-[1px] border-b-slate-700 [&>*]:font-extrabold text-base sm:text-lg',
				},
				content: {
					class: 'text-sm md:text-base',
				},
			},
			AccordionTab: {
				content: {
					class: '!bg-transparent',
				},
				root: {
					class: '',
				},
				headerAction: {
					class: '!bg-transparent [&>*]:font-extrabold text-white [&>*]:hover:!text-slate-300',
				},
			},
			ConfirmPopup: {
				root: {
					class: 'rounded-md bg-slate-900 border-slate-700',
				},
			},
			TieredMenu: {
				root: {
					class: 'z-[51]',
				},
			},
		},
	})
	.directive('tooltip', Tooltip)
	.directive('intersect', intersectDirective)
	.directive('delayed-intersect', delayedIntersectDirective)
	.directive('intersect-outside', intersectOutsideDirective)
	.directive('focus', focusDirective)
	.component('Button', Button)
	.component('Divider', Divider)

useAudioStore()
	.registerTracks(
		{
			trackName: 'upgrade_token',
			path: '/audio/upgrade_token.mp3',
		},
		{
			trackName: 'level_up',
			path: '/audio/level_up_sound.mp3',
		},
		{
			trackName: 'xp_fill',
			path: '/audio/xp_fill.mp3',
		},
		{
			trackName: 'reward',
			path: '/audio/reward.mp3',
		},
		{
			trackName: 'reward_event',
			path: '/audio/reward_event.mp3',
		},
	)

const isMobile = useCompatibilityStore().mobileDevice

useDynamicModalStore().registerComponents([
	{
		importCb: () => import('@/components/user/signup/Login.vue'),
		type: DynamicModalType.Login,
		data: {
			props: { popup: false },
			title: () => t('login.account_creation'),
			icon: User,
			theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
		},
	},
	{
		importCb: () => import('@/components/user/signup/RegisterSuccess.vue'),
		type: DynamicModalType.RegisterSuccess,
		data: {
			title: () => t('register.success.title'),
			icon: MailCheck,
			theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
		},
	},
	{
		importCb: () => import('@/components/user/signup/ForgotPassword.vue'),
		type: DynamicModalType.ForgotPassword,
		data: {
			props: {},
			title: () => t('login.forgot.title'),
			theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
		},
	},
	{
		importCb: () => import('@/components/user/settings/Dashboard.vue'),
		type: DynamicModalType.Settings,
		data: {
			props: {},
			title: () => t('dashboard.title'),
			icon: Settings,
			theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
		},
	},
	{
		importCb: () => import('@/components/menu/MobileMenu.vue'),
		type: DynamicModalType.Menu,
		data: {
			props: {},
			title: () => t('menu.title'),
			icon: Menu,
			theme: DynamicModalTheme.Fullscreen,
		},
	},
	{
		importCb: () => import('@/components/user/LockedFeature.vue'),
		type: DynamicModalType.LockedFeature,
		data: {
			title: () => t('locked_feature.title'),
			icon: Lock,
			theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
		},
	},
	{
		importCb: () => import('@/components/search/styles/SelectStylesModal.vue'),
		type: DynamicModalType.SelectStyles,
		data: {
			title: () => t('styles.select_styles'),
			icon: Palette,
			theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Large,
			shortcuts: [
				{
					label: () => t('shortcuts.select_styles.label'),
					mobile: () => t('shortcuts.select_styles.mobile'),
					desktop: () => t('shortcuts.select_styles.desktop'),
				},
			],
		},
	},
	{
		importCb: () => import('@/components/search/BrowsePromptModal.vue'),
		type: DynamicModalType.Community,
		data: {
			title: () => t('searches.community.title'),
			icon: Globe,
			theme: DynamicModalTheme.Fullscreen,
		},
	},
	{
		importCb: () => import('@/components/game/AvatarEdit.vue'),
		type: DynamicModalType.AvatarEdit,
		data: {
			title: () => t('profile.avatar.title'),
			icon: PencilRuler,
			theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
		},
	},
	{
		importCb: () => import('@/components/notification/NotificationCenter.vue'),
		type: DynamicModalType.Notification,
		data: {
			title: () => t('notification_widget.title'),
			icon: Bell,
			theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
		},
	},
	{
		importCb: () => import('@/components/user/DeleteCollection.vue'),
		type: DynamicModalType.DeleteCollection,
		data: {
			title: () => t('collection.deletion.title'),
			icon: Trash2,
		},
	},
	{
		importCb: () => import('@/components/locale_setter/ModalLocaleSetter.vue'),
		type: DynamicModalType.UpdateLocale,
		data: {
			title: () => t('dashboard.lang.title'),
			icon: Languages,
		},
	},
])

useNotificationStore()
useAuthStore()
useEventGalleryStore()
useSettingStore()
useCompatibilityStore()
useGalleryStore()
useCheckoutPremiumStore()

app.use(i18n)
/*
app.config.errorHandler = (err, vm, info) => {
	console.error('Erreur capturée globalement :', err, vm, info)
}
 */
installServiceWorker().then(() => {
	app.mount('#app')

	if (!import.meta.env.VITE_DOMAIN.includes('dev')) {
		const _mtm = window._mtm = window._mtm || []
		const _paq = window._paq = window._paq || []

		_paq.push(['setCookieDomain', `*.${import.meta.env.VITE_DOMAIN}`])
		_paq.push(['HeatmapSessionRecording::disable'])
		_paq.push(['setRequestMethod', 'POST'])
		_paq.push(['enableHeartBeatTimer'])
		_paq.push(['trackPageView'])
		_mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
		(function () {
			const d = document; const g = d.createElement('script'); const s = d.getElementsByTagName('script')[0]
			g.async = true
			g.src = 'https://mato.fapfap.ai/js/container_iGkpPoVv.js'
			s.parentNode?.insertBefore(g, s)
		})()
	}
})
/*
document.addEventListener('keydown', (event) => {
	if (event.ctrlKey && (event.key === '=' || event.key === '+' || event.key === '-' || event.key === '_')) {
		event.preventDefault()
	}
})

window.addEventListener('wheel', (event) => {
	if (event.ctrlKey) {
		event.preventDefault()
	}
}, { passive: false })
 */
