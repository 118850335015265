import { defineStore } from 'pinia'
import { computed, inject, ref } from 'vue'
import { ArrowUp10, Bomb, FireExtinguisher, Flag, Flame, Info, ScrollText, Swords } from 'lucide-vue-next'
import { addMinutes } from 'date-fns'
import { usePlayerStore } from './playerStore'
import type { ENotificationType, IAppEventNotification, INotification } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { useAuthStore } from '@/stores/authStore'
import { isAppErrorNotification, isAppEventNotification, isAppNotification, isRewardNotification, isXpGainNotification } from '@/types/guards'

interface DelayedNotifications {
	condition: () => boolean
	notifications: INotification[]
}

export const useNotificationStore = defineStore('notif store', () => {
	const request = inject<RequestRouter>('request')
	const authStore = useAuthStore()

	const bellNotifications = ref<INotification[]>([])
	const freshNotifications = ref<INotification[]>([])

	const countNewRewards = ref<number>(0)
	const countNewNotifs = ref<number>(0)

	const newNotifications = computed(() => countNewRewards.value + countNewNotifs.value)

	const delayedNotifications: Partial<Record<ENotificationType, DelayedNotifications>> = {}

	const page = ref<number>(1)
	authStore.addLogoutCallback(() => {
		bellNotifications.value = []
		page.value = 1
	})
	let appId = -1
	const getPreset = (type: ENotificationType) => {
		const preset: Record<ENotificationType, Partial<INotification>> = {
			bundle: {
				titleColor: 'text-yellow-300',
				iconColor: 'text-yellow-300',
				icon: Swords,
			},
			report: {
				iconColor: 'text-indigo-300',
				icon: Flag,
			},
			achievement: {
				iconColor: 'text-teal-300',
				icon: ScrollText,
			},
			daily: {
				iconColor: 'text-red-300',
				icon: Flame,
			},
			daily_loss: {
				iconColor: 'text-blue-300',
				icon: FireExtinguisher,
			},
			level_up: {
				iconColor: 'text-orange-300',
				icon: ArrowUp10,
			},
			app: {
				id: --appId,
				iconColor: 'text-purple-400',
				icon: Info,
				created_at: new Date(),
				new: true,
			},
			app_error: {
				id: --appId,
				iconColor: 'text-red-400',
				icon: Bomb,
				created_at: new Date(),
				new: true,
			},
			app_event: {
				id: --appId,
				iconColor: 'text-purple-400',
				icon: Info,
				created_at: new Date(),
				new: true,
				delay: 60 * 1000 * 3,
				expires_at: addMinutes(new Date(), 3),
				key: 'notification.bundle_event',
			} as IAppEventNotification,
		}
		return preset[type]
	}

	const push = (type: ENotificationType, data: Partial<INotification>, isNew: boolean = false) => {
		const notification: Partial<INotification> = {
			action: () => {},
			new: 'new' in data ? data.new : isNew,
			type,
			delay: 4000,
			...getPreset(type),
			...data,
		}

		if (!notification.consumed) {
			if (isRewardNotification(notification as INotification) || isXpGainNotification(notification as INotification)) {
				notification.action = () => {
					usePlayerStore().claimReward(notification as INotification)
					notification.action = () => {}
				}
			}
			else {
				notification.action = () => {
					notification.action = () => {}
					const notifRef = bellNotifications.value.find(({ id }) => id === notification.id)
					if (notifRef) {
						notifRef.consumed = true
					}
					countNewNotifs.value--
					markRead(notification as INotification)
				}
			}
		}

		const existsInBell = bellNotifications.value.some(
			notif => notif.id === notification.id,
		)

		if (!existsInBell) {
			if (type in delayedNotifications) {
				if (delayedNotifications[type]?.condition()) {
					delayedNotifications[type].notifications.push(notification as INotification)
					return
				}
			}

			displayNewNotification(notification as INotification)
		}
	}

	async function markRead(notification: INotification) {
		await request?.exec(RequestAction.ConsumeNotification, {
			routeParams: {
				id: notification.id,
			},
		})
	}

	function displayNewNotification(notification: INotification): void {
		freshNotifications.value.push(notification)

		if (
			!isAppNotification(notification)
			&& !isAppEventNotification(notification)
			&& !isAppErrorNotification(notification)
		) {
			bellNotifications.value.push(notification)
		}
	}

	async function loadNotifications() {
		const notifContainer = await request?.exec(RequestAction.GetNotifications, {
			routeParams: {
				page: page.value,
				size: 10,
				scopes: [
					'count',
				],
			},
		})
		if (notifContainer) {
			notifContainer.notifs.forEach((notif: INotification) => {
				push(notif.type, notif)
			})
			page.value++

			countNewRewards.value = 0
			countNewNotifs.value = 0
			if (typeof notifContainer.newRewards === 'number') {
				countNewRewards.value += notifContainer.newRewards
			}
			if (typeof notifContainer.newNotifs === 'number') {
				countNewNotifs.value += notifContainer.newNotifs
			}
		}
	}

	function createNotificationDelayer(type: ENotificationType, condition: () => boolean): void {
		delayedNotifications[type] = {
			condition,
			notifications: [],
		}
	}

	function dispatchDelayedNotification(type: ENotificationType): void {
		if (type in delayedNotifications) {
			const { notifications } = delayedNotifications[type] as DelayedNotifications
			delayedNotifications[type]!.notifications = []

			notifications.forEach(notification => displayNewNotification(notification))
		}
	}

	return {
		countNewRewards,
		countNewNotifs,
		bellNotifications,
		freshNotifications,
		newNotifications,
		page,
		loadNotifications,
		push,
		createNotificationDelayer,
		dispatchDelayedNotification,
	}
})
