<script setup lang="ts">
import { nextTick, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import ProgressSpinner from 'primevue/progressspinner'
import { useSettingStore } from '@/stores/settingStore'
import router from '@/router'
import { useAuthStore } from '@/stores/authStore'

const route = useRoute()

const redirectUri = `https://${useSettingStore().getLocale().code}.${import.meta.env.VITE_DOMAIN}/googleauth`

const isOpenedWindow = !!window.opener

const deviceId = localStorage.getItem('deviceId')

localStorage.removeItem('deviceId')

onMounted(async () => {
	if (!route.query.code || useAuthStore().isAuth()) {
		router.push('/404')
		return
	}
	try {
		const res = await fetch('api/user/login/google', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'device-id': deviceId as string,
			},
			body: JSON.stringify({
				google_auth_code: route.query.code,
				redirect_uri: redirectUri,
			}).toString(),
		})
		if (isOpenedWindow) {
			window.opener.postMessage({
				googleAuthSuccess: res.ok,
				error: !res.ok ? await res.json() : null,
			})
		}
	}
	catch (err) {
		if (isOpenedWindow) {
			window.opener.postMessage({
				googleAuthSuccess: false,
				error: err,
			})
		}
	}
	finally {
		if (isOpenedWindow) {
			window.close()
		}
		else {
			router.push('/')
			await nextTick()
			await useAuthStore().loginGoogle()
		}
	}
})
</script>

<template>
	<div class="fixed w-screen h-[100dvh] z-[1000] bg-black flex-center">
		<ProgressSpinner
			style="width: 80px; height: 80px" stroke-width="3"
			animation-duration=".6s" aria-label="connection process in progress"
		/>
	</div>
</template>
