import { defineStore, storeToRefs } from 'pinia'
import { computed, inject, nextTick, reactive, ref, shallowRef, watch } from 'vue'
import { addYears } from 'date-fns'
import { useRoute, useRouter } from 'vue-router'
import { useSettingStore } from './settingStore'
import { useGalleryStore } from './galleryStore'
import { useAuthStore } from './authStore'
import { useDynamicModalStore } from './dynamicModalStore'
import type { BrowsePrompt, GraphicStyle, PromptSaveBody, PromptSearchBody, PromptTag, PromptType } from '@/types'
import { DynamicModalType } from '@/types'
import Tools from '@/utils/tools'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'

export const useSearchStore = defineStore('search store V2', () => {
	const request = inject<RequestRouter>('request')
	const settingStore = useSettingStore()
	const router = useRouter()
	const route = useRoute()
	const openPanel = shallowRef<boolean>(false)
	const shuffle = reactive({
		current: true,
		prev: true,
	})
	const imagesFound = ref(1)
	const draggingSource = shallowRef<PromptType | null>(null)
	const selectedStyles = ref<number[]>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
	const graphicStyles = ref<GraphicStyle[]>([])
	const loadedSearchFromBrowse = ref<number | null>(null)
	const loadedSearch = ref<number>(0)

	const quickSavedAccess = ref<BrowsePrompt[]>([])
	const { galleryScroll, restoreScroll, seed, timestamp } = storeToRefs(settingStore)
	const prompts = reactive<{
		pos: PromptTag[]
		neg: PromptTag[]
		opt: PromptTag[]
	}>({
		neg: [],
		pos: [],
		opt: [],
	})

	let visitorLoading = 0

	const screenWidth = ref(window.innerWidth)

	window.addEventListener('resize', () => {
		screenWidth.value = window.innerWidth
	})

	setTimeout(() => {
		imagesFound.value = 0
	}, 500)

	watch(
		() => openPanel.value,
		(open: boolean) => {
			if (open) {
			// Ajouter "search=open" à la query
				router.push({ query: { ...route.query, search: 'open' } })
			}
			else if (route.query.search) {
			// Supprimer "search" sans appeler router.push directement
				const { search, ...restQuery } = route.query
				router.replace({ query: { ...restQuery } })
			}
		},
	)

	watch(
		() => route.query.search,
		(search) => {
			// Met à jour l'état du panneau en fonction de la query
			openPanel.value = search === 'open'
		},
	)

	function saveStylesCookie(): void {
		Tools.setCookie(
			'selected-styles',
			JSON.stringify(selectedStyles.value),
			addYears(new Date(), 1),
			`.${import.meta.env.VITE_DOMAIN}`,
			'/',
		)
	}

	function loadGraphicStyles() {
		request?.exec(RequestAction.GetGraphicStyles)
			.then((styles: GraphicStyle[]) => {
				graphicStyles.value = styles.map((style) => {
					style.preview = `https://static.fapfap-image.workers.dev/prod/image/${style.preview}`
					return style
				})
				const cookie = Tools.getCookie('selected-styles')
				if (cookie === null) {
					selectedStyles.value.length = 0
					styles.forEach(({ id }) => selectedStyles.value.push(id))
				}
				else {
					const parsed = JSON.parse(cookie)
					selectedStyles.value.length = 0
					parsed.forEach((id: number) => selectedStyles.value.push(id))
				}
			})
			.catch(() => {
				console.error('cannot load graphic styles')
			})
	}

	loadGraphicStyles()

	const emptyPrompts = computed(() => !prompts.neg.length && !prompts.pos.length && !prompts.opt.length)

	const searchBody = computed((): PromptSearchBody => {
		return {
			pos_search: prompts.pos.map(({ id }) => id),
			opt_search: prompts.opt.map(({ id }) => id),
			neg_search: prompts.neg.map(({ id }) => id),
			shuffle: shuffle.current,
			styles: selectedStyles.value,
		}
	})

	const search = Tools.createDebouncedFunction(async () => {
		const headers = new Headers()

		headers.append('Seed', seed.value.toString())
		headers.append('Timestamp', timestamp.value)

		const data = await request?.exec(RequestAction.PromptSearch, {
			env: {
				headers,
			},
			routeParams: {
				page: 1,
				size: 12,
			},
			body: searchBody.value,
		})
		if (data) {
			imagesFound.value = data.total
			const store = useGalleryStore()
			const { images, page } = storeToRefs(store)
			images.value = data.items
			page.value = 1
			galleryScroll.value = 0
			restoreScroll.value()
		}
	}, 100)

	function selectStyle(styleId: number) {
		const index = selectedStyles.value.indexOf(styleId)
		if (index === -1) {
			selectedStyles.value = [...selectedStyles.value, styleId]
		}
		else if (selectedStyles.value.length !== 1) {
			selectedStyles.value = [
				...selectedStyles.value.slice(0, index),
				...selectedStyles.value.slice(index + 1),
			]
		}
		saveStylesCookie()
		search()
	}

	function setOneStyleOnly(styleId: number): void {
		if (selectedStyles.value.length === 1 && styleId === selectedStyles.value[0]) {
			return
		}
		const { length } = selectedStyles.value
		selectedStyles.value = [styleId]
		saveStylesCookie()
		if (length !== selectedStyles.value.length) {
			search()
		}
	}

	function setDefaultStyles(): void {
		const { length } = selectedStyles.value
		selectedStyles.value = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
		saveStylesCookie()
		if (length !== selectedStyles.value.length) {
			search()
		}
	}

	async function savePrompt(title: string, publish: boolean) {
		const body: PromptSaveBody = {
			lib: title,
			pos_tags: prompts.pos.map(({ id }) => id),
			opt_tags: prompts.opt.map(({ id }) => id),
			neg_tags: prompts.neg.map(({ id }) => id),
			styles: selectedStyles.value,
			public: publish,
		}

		const id = await request?.exec(RequestAction.PromptSave, {
			body,
		})
		if (!Number.isNaN(id)) {
			addToQuickAccess({
				id,
				lib: title,
				styles: [...selectedStyles.value],
				pos_tags: prompts.pos.reduce((acc, tag) => {
					acc[tag.value] = tag.id
					return acc
				}, {} as Record<string, number>),
				opt_tags: prompts.opt.reduce((acc, tag) => {
					acc[tag.value] = tag.id
					return acc
				}, {} as Record<string, number>),
				neg_tags: prompts.neg.reduce((acc, tag) => {
					acc[tag.value] = tag.id
					return acc
				}, {} as Record<string, number>),
			} as BrowsePrompt)
		}
	}

	function addToQuickAccess(prompt: BrowsePrompt): void {
		quickSavedAccess.value.unshift(prompt)
	}

	async function setPrompt(data: BrowsePrompt) {
		const authStore = useAuthStore()
		const dynamicModalStore = useDynamicModalStore()
		openPanel.value = true
		if (!authStore.isAuth()) {
			visitorLoading++
			if (visitorLoading >= 3) {
				dynamicModalStore.showModal(DynamicModalType.LockedFeature, {
					props: {
						messageI18n: 'locked_feature.message_load_prompt',
					},
				})
				return
			}
		}

		data.is_subscribable = authStore.isAuth()
		&& !data.is_subscribed
		&& typeof data?.user_id === 'number'
		&& authStore.user.id !== data.user_id

		loadedSearchFromBrowse.value = null
		await nextTick()
		const loadedIndex = quickSavedAccess.value.findIndex(({ lib }) => lib === data.lib)
		if (loadedIndex === -1) {
			addToQuickAccess(data)
		}
		loadedSearchFromBrowse.value = data.id

		selectedStyles.value = data.styles
		prompts.pos = mapPrompts(data.pos_tags)
		prompts.opt = mapPrompts(data.opt_tags)
		prompts.neg = mapPrompts(data.neg_tags)

		document.querySelector('#search-panel')?.scrollTo({ top: 0, behavior: 'smooth' })
		search()
	}

	function mapPrompts(promptMap: Record<string, number>): PromptTag[] {
		if (promptMap) {
			return Object.entries(promptMap).map(([value, id]) => ({
				id,
				value,
				count: 0,
			}))
		}
		else {
			return []
		}
	}

	let prevShuffleSaved = false
	watch(
		() => prompts.opt.length,
		(newLength) => {
			if (newLength && !prevShuffleSaved) {
				prevShuffleSaved = true
				shuffle.prev = shuffle.current
				shuffle.current = false
			}
			else if (prevShuffleSaved && !newLength) {
				shuffle.current = shuffle.prev
				prevShuffleSaved = false
			}
		},
	)

	watch(
		() => shuffle.current,
		() => {
			if (prompts.opt.length) {
				return
			}
			shuffle.prev = !shuffle.current
			if (shuffle.current) {
				const settingsStore = useSettingStore()
				settingsStore.resetTimestamp()
				settingsStore.randomizeSeed()
			}
			search()
		},
		{ deep: true },
	)

	function addTag(tag: PromptTag, type: PromptType): void {
		prompts[type].push(tag)
		search()
	}

	function deleteTag(tag: PromptTag | string, type: PromptType): void {
		if (!tag)
			return

		prompts[type] = prompts[type].filter((existingTag) => {
			if (typeof tag === 'string') {
				// Compare with the value if tag is a string
				return existingTag.value !== tag
			}
			else {
				// Compare with the id if tag is a PromptTag
				return existingTag.id !== tag.id
			}
		})

		search()
	}

	function popTag(type: PromptType): PromptTag | null {
		const data = prompts[type].pop() ?? null
		if (data) {
			search()
		}
		return data
	}

	async function loadQuickAccess() {
		if (!useAuthStore().isAuth()) {
			return
		}
		const res = await request?.exec(RequestAction.GetBrowsePrompts, {
			routeParams: {
				page: 1,
				size: 100,
				target: 'saved',
			},
			body: {
				search: '',
				target: 'lib',
				sort: 'date',
				sort_desc: true,
				and_subscribed: true,
			},
		})
		if (res) {
			quickSavedAccess.value = res.items
		}
	}

	(() => loadQuickAccess())()

	return {
		search,
		selectStyle,
		setOneStyleOnly,
		savePrompt,
		setPrompt,
		addTag,
		deleteTag,
		popTag,
		setDefaultStyles,
		loadQuickAccess,
		loadedSearchFromBrowse,
		loadedSearch,
		draggingSource,
		openPanel,
		shuffle,
		history,
		graphicStyles,
		imagesFound,
		searchBody,
		selectedStyles,
		prompts,
		emptyPrompts,
		quickSavedAccess,
	}
})
