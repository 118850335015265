<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/authStore'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import { DynamicModalType } from '@/types'
import DeviceFingerprint from '@/utils/deviceFingerprint'
import Menu from '@/components/menu/Menu.vue'
import { usePlayerStore } from '@/stores/playerStore'

const route = useRoute()
const BottomMenu = defineAsyncComponent(() => import('@/components/menu/BottomMenu.vue'))
const DynamicModal = defineAsyncComponent(() => import('@/components/dynamic_modal/DynamicModal.vue'))
const CookieBanner = defineAsyncComponent(() => import('@/components/CookieBanner.vue'))
const NotificationPanel = defineAsyncComponent(() => import('@/components/notification/NotificationPanel.vue'))
const LevelUp = defineAsyncComponent(() => import('@/components/game/LevelUp.vue'))
/* const Txt2Img = defineAsyncComponent(() => import('@/components/TEST/Text2Img.vue'))
const ChatBot = defineAsyncComponent(() => import('@/components/TEST/LLamaChatBot.vue'))
 */
const authStore = useAuthStore()
const playerStore = usePlayerStore()
const dynamicModalStore = useDynamicModalStore()

const { user } = storeToRefs(authStore)
const { claimDone } = storeToRefs(playerStore)

/* onMounted(() => {
 	document.addEventListener('contextmenu', (event) => {
		// Check if the user is not premium
		if (!user.value.premium.active) {
			// Always prevent right-click context menu
			event.preventDefault()

			// Check if the target is an image
			if (event.target instanceof HTMLImageElement) {
				dynamicModalStore.displayUserModal(DynamicModalType.LockedFeature, { messageI18n: 'locked_feature.message_download', doNotShow: false })
			}
		}
	})
}) */

DeviceFingerprint.create()
</script>

<template>
	<div class="relative">
		<Menu v-if="route.path !== '/home'" />
		<router-view v-slot="{ Component, route }">
			<keep-alive :include="['Gallery']">
				<component :is="Component" :key="route.name" />
			</keep-alive>
		</router-view>
		<DynamicModal />
		<CookieBanner v-if="route.path !== '/home'" />
		<BottomMenu v-if="route.path !== '/home'" />
		<NotificationPanel />
		<LevelUp v-if="!claimDone" v-model:done="claimDone" />
		<!-- 		<ChatBot /> -->
	</div>
</template>

<style lang="scss">

</style>
