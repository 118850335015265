import { createRouter, createWebHistory } from 'vue-router'
import { storeToRefs } from 'pinia'
import { CLocales } from './constants'
import { useSettingStore } from './stores/settingStore'
import Leaderboard from './components/game/leaderboard/Leaderboard.vue'
import CurveTester from './components/game/CurveTester.vue'
import { EImageGalleryType } from './types'
import About from '@/components/About.vue'
import AgeChecker from '@/components/AgeChecker.vue'
import Favorites from '@/components/Favorites.vue'
import Gallery from '@/components/Gallery.vue'
import ZoomedImage from '@/components/image/ZoomedImage.vue'
import Premium from '@/components/premium/Premium.vue'
import GoogleAuth from '@/components/google/GoogleAuthComplete.vue'
import Profile from '@/components/game/Profile.vue'
import Tools from '@/utils/tools'
import { useAuthStore } from '@/stores/authStore'
import PageNotFound from '@/components/404.vue'
import EventGallery from '@/components/EventGallery.vue'

const routes = [
	{
		path: '/about',
		name: 'Home',
		component: About,
		meta: {
			keepAlive: false,
			title: '@meta.about_title',
			description: '@meta.about_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.about_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/profile/:id/:username?',
		name: 'Profile',
		component: Profile,
		meta: {
			keepAlive: false,
			title: '@meta.about_title',
			description: '@meta.about_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.about_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/leaderboard',
		name: 'Leaderboard',
		component: Leaderboard,
		meta: {
			keepAlive: false,
			title: '@meta.about_title',
			description: '@meta.about_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.about_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/premium',
		name: 'Premium',
		component: Premium,
		meta: {
			keepAlive: false,
			title: '@meta.premium_title',
			description: '@meta.premium_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.premium_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: {
			name: '404',
		},
	},
	{
		path: '/404',
		name: '404',
		component: PageNotFound,
		meta: {
			keepAlive: false,
			title: '@meta.404_title',
			description: '@meta.404_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.404_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/home',
		name: 'Age Checker',
		component: AgeChecker,
		meta: {
			keepAlive: false,
			title: '@meta.gallery_title',
			description: '@meta.ageverification_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.ageverification_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/',
		name: 'Gallery',
		component: Gallery,
		meta: {
			keepAlive: true,
			title: '@meta.gallery_title',
			description: '@meta.gallery_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.gallery_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/event/:uuid',
		name: 'Event Gallery',
		component: EventGallery,
		props: true,
		meta: {
			keepAlive: false,
			title: '@meta.gallery_title',
			description: '@meta.gallery_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.gallery_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/collection',
		name: 'Collection',
		component: Favorites,
		meta: {
			keepAlive: true,
			requiresAuth: true,
			title: '@meta.collection_title',
		},
	},
	{
		path: '/image/:uuid',
		name: 'Image',
		component: ZoomedImage,
		props: true,
		meta: {
			keepAlive: false,
			title: '@meta.fullscreen_image_title',
			description: '@meta.fullscreen_image_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai - Image',
				'og:description': '@meta.fullscreen_image_description', // Start by @, picked up by i18n
				'og:url': `${import.meta.env.VITE_HOST}/image/::uuid::`,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/img/::uuid::.png`, // value between ::uuid:: will be replaced by the value of the prop uuid
				'og:image:width': '816',
				'og:image:height': '1176',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/googleauth',
		name: 'Google Auth',
		component: GoogleAuth,
		meta: {
			keepAlive: false,
			title: '@meta.gallery_title',
			description: '@meta.gallery_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.gallery_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/anim_generator',
		name: 'Animation Generator',
		component: CurveTester,
		meta: {
			keepAlive: false,
			title: '@meta.gallery_title',
			description: '@meta.gallery_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.gallery_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
]

const router = createRouter({
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (to.query.search || from.query.search) {
			return false
		}
		if (savedPosition) {
			return savedPosition
		}

		if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth',
			}
		}
		return { top: 0 }
	},

	routes,
})

router.beforeEach((to, from, next) => {
	const viewport = document.querySelector('meta[name="viewport"]')

	if (viewport && 'content' in viewport) {
		viewport.content = 'initial-scale=1.0'
		viewport.content = 'width=device-width'
	}

	if (to.name === 'Gallery') {
		useSettingStore().galleryType = EImageGalleryType.Gallery
	}

	if (to.name === 'Collection') {
		useSettingStore().galleryType = EImageGalleryType.Favorite
	}

	createAlternateLinks(to.path)

	const authStore = useAuthStore()

	const settings = useSettingStore()
	const { isBot } = storeToRefs(settings)

	if (isBot.value || to.name === 'Google Auth') {
		return next()
	}

	const legalAge = Tools.getCookie('age-verification') === 'true'

	if (!legalAge && to.name !== 'Age Checker') {
		localStorage.setItem('redirectUrl', to.fullPath)
		next({ name: 'Age Checker' })
	}
	else if (to.meta.requiresAuth && !authStore.isAuth()) {
		next({ name: 'Gallery' })
	}
	else {
		next()
	}
})

function createAlternateLinks(path: string) {
	const oldLinks = document.querySelectorAll('link[rel="alternate"]')
	oldLinks.forEach((link) => {
		document.head.removeChild(link)
	})

	const oldCanonical = document.querySelector('link[rel="canonical"]')
	if (oldCanonical) {
		document.head.removeChild(oldCanonical)
	}

	CLocales.forEach(({ code }) => {
		const link = document.createElement('link')
		link.rel = 'alternate'
		link.hreflang = code
		link.href = `${import.meta.env.VITE_PROTOCOL}://${code}.${import.meta.env.VITE_DOMAIN}${path}`
		document.head.appendChild(link)
	})

	const link = document.createElement('link')
	link.rel = 'alternate'
	link.hreflang = 'x-default'
	link.href = `${import.meta.env.VITE_HOST}${path}`
	document.head.appendChild(link)

	const canonical = document.createElement('link')
	canonical.rel = 'canonical'
	canonical.href = `${import.meta.env.VITE_HOST}${path}`
	document.head.appendChild(canonical)
}

export default router
