import type { Ref } from 'vue'
import { ref, shallowRef, watch } from 'vue'
import Tools from '@/utils/tools'

export interface IUseAutoScroll {
	start: Ref<boolean>
	speed: Ref<number>
	clockTick: Ref<number>
	fullscreenScroll: Ref<boolean>
	stop: () => void
	setTarget: (target: HTMLElement | Window & typeof globalThis) => void
	toggleAutoScrollSpeed: () => void
}

const start = shallowRef<boolean>(false)
const speed = shallowRef<number>(0)
const speedFS = shallowRef<number>(Tools.map(speed.value, 0, 4, 30000, 4000))
const clockTick = shallowRef<number>(1)
const fullscreenScroll = shallowRef<boolean>(false)
let target: HTMLElement | Window & typeof globalThis = window
let animationFrameId: number = 0
let scrollAccumulator: number = 0

let startTs = 0
let lastFrameTime = 0

watch(() => speed.value, () => {
	speedFS.value = Tools.map(speed.value, 0, 4, 30000, 4000)
})

const speedStep = 1
let speedMult = 0

document.body.addEventListener('wheel', stop)
document.body.addEventListener('touchmove', stop)

function toggleAutoScrollSpeed(): void {
	speedMult++
	const newSpeed = speedStep * speedMult
	if (!start.value) {
		speed.value = newSpeed
		start.value = true
	}
	else {
		if (newSpeed > 4) {
			stop()
		}
		else {
			speed.value = newSpeed
		}
	}
}

function run() {
	if (start.value) {
		const currentFrameTime = performance.now()
		const deltaTime = currentFrameTime - lastFrameTime
		lastFrameTime = currentFrameTime

		if (!startTs) {
			startTs = currentFrameTime
		}
		else {
			const elapsed = currentFrameTime - startTs
			const tick = Math.floor(Tools.map(elapsed, 0, speedFS.value, 1, 13))
			clockTick.value = tick === 13 ? 1 : tick

			if (elapsed >= speedFS.value) {
				startTs = currentFrameTime
			}
		}

		// Calculate scroll increment based on deltaTime for any refresh rate
		scrollAccumulator += speed.value * (deltaTime / (1000 / 60)) // normalize to 60Hz baseline

		// Scroll only when scrollAccumulator has at least 1 pixel of movement
		if (scrollAccumulator >= 1) {
			if (!fullscreenScroll.value) {
				target.scrollBy(0, Math.floor(scrollAccumulator))
			}

			scrollAccumulator -= Math.floor(scrollAccumulator)
		}

		animationFrameId = requestAnimationFrame(run)
	}
}

watch(() => start.value, () => {
	if (start.value) {
		lastFrameTime = performance.now()
		run()
	}
	else {
		clockTick.value = 1
		startTs = 0
		scrollAccumulator = 0
		animationFrameId = 0
		cancelAnimationFrame(animationFrameId)
	}
})

function stop() {
	start.value = false
	speed.value = 0
	speedMult = 0
}

function setTarget(_target: HTMLElement | Window & typeof globalThis = window) {
	target = _target
}

export function useAutoScroll(): IUseAutoScroll {
	return { toggleAutoScrollSpeed, start, speed, stop, setTarget, clockTick, fullscreenScroll }
}
