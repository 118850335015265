import router from '@/router'
import { useAuthStore } from '@/stores/authStore'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import { DynamicModalType } from '@/types'

export interface IUseFavorites {
	routeToFavorites: () => void
}

export function useFavorites(): IUseFavorites {
	const authStore = useAuthStore()
	const dynamicModalStore = useDynamicModalStore()

	function routeToFavorites() {
		if (!authStore.isAuth()) {
			dynamicModalStore.showModal(DynamicModalType.LockedFeature, {
				props: { messageI18n: 'locked_feature.message_route_collection' },
			})
		}
		else {
			router.push('/collection')
		}
	}
	return { routeToFavorites }
}
