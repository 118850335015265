import type FapFapError from '@/errors/FapFapError'
import type {
	BrowsePrompt,
	BrowseSearchBody,
	GraphicStyle,
	IAchievement,
	IImage,
	IItemReward,
	IItemRewardContainer,
	ILeaderboardUserStat,
	IMembershipTierAPI,
	INotificationContainer,
	IPage,
	IPlayer,
	IProfile,
	IUser,
	IUserDeviceAPI,
	IUserLoginAPI,
	IUserMembershipAPI,
	IUserRegistration,
	IUserUpdateAPI,
	PromptSaveBody,
	PromptSearchBody,
	PromptTag,
} from '@/types'

export enum RequestAction {
	GetOrders,
	GetMembershipTiers,
	GetUserDevices,
	DeleteUserDevice,
	AddImageToFavorites,
	DeleteImageFromFavorites,
	DeleteImagesFromFavorites,
	ReportImage,
	UpdateUser,
	GetUserSettings,
	GetUserPlayer,
	UpdateAvatar,
	UpdateTitle,
	Login,
	Logout,
	ResetPassword,
	CreateUser,
	GetB2Token,
	GetImageInfo,
	SetCloudflareToken,
	PlacePaypalOrder,
	CompletePaypalOrder,
	GetNotifications,
	ConsumeNotification,
	ConsumeAllNotifications,
	GetRewards,
	GetAchievements,
	GetUserProfile,
	GetProfileAchievements,
	ConvertToken,
	ConsumeTokenXp,
	ConsumeToken,
	GetLeaderboard,
	ConsumeEvent,
	SetReaction,
	GetEvent,
	GetVisitorEvent,
	GetEventTokenReward,
	GetImageCount,
	PromptSearch,
	TagSearch,
	PromptSave,
	PromptDelete,
	PromptPublish,
	GetBrowsePrompts,
	GetGraphicStyles,
	SubscribePrompt,
	GetFavorites,
}

export type RequestKey = keyof RequestReturnTypes & keyof RequestBodyTypes

export interface RequestVarBucket<K extends RequestKey> {
	routeParams?: Record<string, string | number | boolean | string[]> | null
	env?: Record<string, any>
	body?: RequestBodyTypes[K]
}

export interface RequestReturnTypes {
	[RequestAction.GetOrders]: IUserMembershipAPI[]
	[RequestAction.GetMembershipTiers]: IMembershipTierAPI[]
	[RequestAction.GetUserDevices]: IUserDeviceAPI[]
	[RequestAction.DeleteUserDevice]: Response
	[RequestAction.AddImageToFavorites]: Response
	[RequestAction.DeleteImageFromFavorites]: Response
	[RequestAction.DeleteImagesFromFavorites]: Response
	[RequestAction.ReportImage]: Response
	[RequestAction.UpdateUser]: Response
	[RequestAction.GetUserSettings]: IUser | null
	[RequestAction.GetUserPlayer]: IPlayer | null
	[RequestAction.Login]: Response
	[RequestAction.Logout]: Response
	[RequestAction.ResetPassword]: Response
	[RequestAction.CreateUser]: Response
	[RequestAction.GetB2Token]: string
	[RequestAction.GetImageInfo]: IImage | FapFapError
	[RequestAction.SetCloudflareToken]: void
	[RequestAction.PlacePaypalOrder]: any
	[RequestAction.CompletePaypalOrder]: any
	[RequestAction.GetNotifications]: INotificationContainer
	[RequestAction.ConsumeNotification]: Response
	[RequestAction.GetRewards]: IItemRewardContainer
	[RequestAction.GetAchievements]: IAchievement[]
	[RequestAction.GetUserProfile]: IProfile
	[RequestAction.GetProfileAchievements]: IAchievement[]
	[RequestAction.ConvertToken]: boolean
	[RequestAction.ConsumeTokenXp]: number
	[RequestAction.ConsumeToken]: IItemReward
	[RequestAction.UpdateAvatar]: Response
	[RequestAction.UpdateTitle]: Response
	[RequestAction.ConsumeAllNotifications]: Response
	[RequestAction.GetLeaderboard]: IPage<ILeaderboardUserStat>
	[RequestAction.ConsumeEvent]: IPage<IImage>
	[RequestAction.SetReaction]: Response
	[RequestAction.GetEvent]: Response
	[RequestAction.GetVisitorEvent]: Response
	[RequestAction.GetEventTokenReward]: Response
	[RequestAction.GetImageCount]: number
	[RequestAction.PromptSearch]: IPage<IImage>
	[RequestAction.TagSearch]: PromptTag[]
	[RequestAction.GetFavorites]: IPage<IImage>
	[RequestAction.PromptSave]: number
	[RequestAction.PromptDelete]: Response
	[RequestAction.GetGraphicStyles]: GraphicStyle[]
	[RequestAction.GetBrowsePrompts]: IPage<BrowsePrompt>
	[RequestAction.SubscribePrompt]: Response
	[RequestAction.PromptPublish]: Response
}

export interface RequestBodyTypes {
	[RequestAction.GetOrders]: undefined
	[RequestAction.GetMembershipTiers]: undefined
	[RequestAction.GetUserDevices]: undefined
	[RequestAction.DeleteUserDevice]: undefined
	[RequestAction.AddImageToFavorites]: { uuid: string }
	[RequestAction.DeleteImageFromFavorites]: { uuid: string }
	[RequestAction.DeleteImagesFromFavorites]: { uuid: string[] } | { images: Record<'uuid', string>[] }
	[RequestAction.ReportImage]: { uuid: string }
	[RequestAction.UpdateUser]: Partial<IUserUpdateAPI>
	[RequestAction.GetUserSettings]: undefined
	[RequestAction.GetUserPlayer]: undefined
	[RequestAction.Login]: IUserLoginAPI
	[RequestAction.Logout]: undefined
	[RequestAction.ResetPassword]: { login: string }
	[RequestAction.CreateUser]: IUserRegistration
	[RequestAction.GetB2Token]: undefined
	[RequestAction.GetImageInfo]: undefined
	[RequestAction.SetCloudflareToken]: undefined
	[RequestAction.PlacePaypalOrder]: { month_duration: number }
	[RequestAction.CompletePaypalOrder]: undefined
	[RequestAction.GetNotifications]: undefined
	[RequestAction.ConsumeNotification]: undefined
	[RequestAction.GetRewards]: undefined
	[RequestAction.GetAchievements]: undefined
	[RequestAction.GetUserProfile]: undefined
	[RequestAction.GetProfileAchievements]: undefined
	[RequestAction.ConvertToken]: undefined
	[RequestAction.ConsumeTokenXp]: undefined
	[RequestAction.ConsumeToken]: undefined
	[RequestAction.UpdateAvatar]: { items: number[] }
	[RequestAction.UpdateTitle]: undefined
	[RequestAction.ConsumeAllNotifications]: undefined
	[RequestAction.GetLeaderboard]: undefined
	[RequestAction.ConsumeEvent]: undefined
	[RequestAction.SetReaction]: { uuid: string }
	[RequestAction.GetEvent]: undefined
	[RequestAction.GetVisitorEvent]: undefined
	[RequestAction.GetEventTokenReward]: undefined
	[RequestAction.GetImageCount]: undefined
	[RequestAction.PromptSearch]: PromptSearchBody
	[RequestAction.TagSearch]: undefined
	[RequestAction.GetFavorites]: { shuffle: boolean }
	[RequestAction.PromptSave]: PromptSaveBody
	[RequestAction.PromptDelete]: undefined
	[RequestAction.GetGraphicStyles]: undefined
	[RequestAction.GetBrowsePrompts]: BrowseSearchBody
	[RequestAction.SubscribePrompt]: undefined
	[RequestAction.PromptPublish]: undefined
}

export type RequestMiddleware = (response: Response) => Promise<boolean>
export type RequestConfig<B = any> = Omit<RequestInit, 'headers' | 'body'> & {
	headers?: Record<string, string>
	body?: B
}

export type ApiRequest<T = Response, A extends RequestAction = RequestAction> =
  T extends Response
  	? {
  			action: A
  			endpoint: string
  			config: RequestConfig
  			middlewares?: RequestMiddleware[]
  			beforeRequest?: (
  				config: RequestConfig<RequestBodyTypes[A]>,
  				env: Record<string, any>
  			) => RequestConfig<RequestBodyTypes[A]> | Promise<RequestConfig<RequestBodyTypes[A]>>
  			afterRequest?: undefined
  		}
  	: T extends boolean
  		? {
  				action: A
  				endpoint: string
  				config: RequestConfig
  				middlewares?: RequestMiddleware[]
  				beforeRequest?: (
  					config: RequestConfig<RequestBodyTypes[A]>,
  					env: Record<string, any>
  				) => RequestConfig<RequestBodyTypes[A]> | Promise<RequestConfig<RequestBodyTypes[A]>>
  				afterRequest: (response: Response, env: Record<string, any>) => boolean | Promise<boolean>
  			}
  		: {
  				action: A
  				endpoint: string
  				config: RequestConfig
  				middlewares?: RequestMiddleware[]
  				beforeRequest?: (
  					config: RequestConfig<RequestBodyTypes[A]>,
  					env: Record<string, any>
  				) => RequestConfig<RequestBodyTypes[A]> | Promise<RequestConfig<RequestBodyTypes[A]>>
  				afterRequest: (response: Response, env: Record<string, any>) => T | Promise<T>
  			}

export interface RequestRoutes {
	prefix: string
	middlewares?: RequestMiddleware[]
	queries: ApiRequest<any, any>[]
}

export class RequestRouter {
	private _actions: Map<RequestAction, <K extends RequestKey>(data: RequestVarBucket<K>) => Promise<any>> = new Map()

	private async execMiddlewares(response: Response, middlewares: RequestMiddleware[]): Promise<boolean> {
		for (let i = 0; i < middlewares.length; i++) {
			if (!(await middlewares[i](response))) {
				return false
			}
		}
		return true
	}

	/**
	 * Helper function to parse a filter and its arguments.
	 * Supports format like 'join(,)' or 'uppercase' (no arguments).
	 */
	private parseFilter(filterPart: string): [string, string | undefined] {
		const filterMatch = filterPart.match(/^(\w+)(\(([^)]*)\))?/) // Capture the filter name and its arguments, if any
		const filterName = filterMatch?.[1] // Filter name, e.g., 'join'
		const filterArgs = filterMatch?.[3] // Arguments within parentheses, e.g., ','
		return [filterName || '', filterArgs] // Return filter name and its argument (undefined if none)
	}

	private replacePlaceholders(template: string, routeParams: Record<string, string | number | boolean | string[]>): string {
		return template.replace(/\{\{\s*(\w+)(?:=(.*?))?((\|\w+(\([^)]*\))?)*)\s*\}\}/g, (placeholder, key, defaultValue, filters) => {
			let value = routeParams[key]

			// Use default value if the key is not found or the value is undefined/null
			if (value === undefined || value === null) {
				if (defaultValue !== undefined) {
					value = defaultValue // Assign the default value
				}
				else {
					throw new Error(`Missing parameter "${key}" for template "${template}".`)
				}
			}

			// If filters are present, apply them in sequence
			if (filters) {
				// Split the filters based on the pipe '|' symbol
				const filterParts: string[] = filters.split('|').filter(Boolean) // Remove empty parts

				// Iterate over the filters and apply each one
				filterParts.forEach((filterPart) => {
					const [filterName, filterArgs] = this.parseFilter(filterPart) // Parse each filter and its arguments

					switch (filterName) {
						case 'join':
							if (Array.isArray(value)) {
								value = value.join(filterArgs || ',') // Default to comma if no argument is provided
							}
							else {
								throw new TypeError(`Filter "join" can only be applied to arrays. Received: ${typeof value}`)
							}
							break

						case 'uppercase':
							if (typeof value === 'string') {
								value = value.toUpperCase()
							}
							else {
								throw new TypeError(`Filter "uppercase" can only be applied to strings. Received: ${typeof value}`)
							}
							break

						case 'lowercase':
							if (typeof value === 'string') {
								value = value.toLowerCase()
							}
							else {
								throw new TypeError(`Filter "lowercase" can only be applied to strings. Received: ${typeof value}`)
							}
							break

						case 'bool':
							if (typeof value === 'boolean') {
								const [trueValue, falseValue] = filterArgs?.split(',') || []
								value = value ? trueValue : falseValue
							}
							else {
								throw new TypeError(`Filter "bool" can only be applied to booleans. Received: ${typeof value}`)
							}
							break

						default:
							throw new Error(`Unsupported filter "${filterName}".`)
					}
				})
			}

			// Return the final transformed value
			return String(value)
		})
	}

	add(routes: RequestRoutes): this {
		routes.queries.forEach((query: ApiRequest<any>) => {
			const func = async <K extends RequestKey>(data: RequestVarBucket<K>): Promise<any> => {
				const routeParams = data.routeParams || {}
				const env = data.env || {}

				let route = routes.prefix
				if (query.endpoint) {
					const separator = query.endpoint.startsWith('?') ? '' : '/'
					route = this.replacePlaceholders(`${routes.prefix}${separator}${query.endpoint}`, routeParams)
				}

				let requestConfig = { ...query.config }
				if (data.body) {
					requestConfig.body = data.body
				}
				requestConfig = await this.handleBeforeRequest(query, requestConfig, env)
				requestConfig = this.finalizeRequestConfig(requestConfig)

				const response = await this.executeRequest(route, requestConfig, routes.middlewares, query.middlewares)

				if (query.afterRequest) {
					return await query.afterRequest(response, env)
				}
				else {
					return response
				}
			}

			this._actions.set(query.action, func)
		})
		return this
	}

	private async handleBeforeRequest(query: ApiRequest<any>, config: RequestConfig, env: Record<string, any>): Promise<RequestConfig> {
		if (query.beforeRequest) {
			config = await query.beforeRequest(config, env)
		}
		return config
	}

	private finalizeRequestConfig(config: RequestConfig): RequestConfig {
		if (config.body && typeof config.body === 'object' && !(config.body instanceof FormData)) {
			config.body = JSON.stringify(config.body)
			config.headers = {
				...config.headers,
			}
			if (!config.headers['Content-Type']) {
				config.headers['Content-Type'] = 'application/json'
			}
		}

		return config
	}

	private async executeRequest(route: string, config: RequestConfig, routeMiddlewares?: RequestMiddleware[], queryMiddlewares?: RequestMiddleware[]): Promise<Response> {
		const response = await fetch(route, config)

		if (Array.isArray(routeMiddlewares)) {
			const middlewaresPassed = await this.execMiddlewares(response, routeMiddlewares)
			if (!middlewaresPassed) {
				throw new Error(`The request to "${route}" failed due to middleware constraints.`)
			}
		}
		if (Array.isArray(queryMiddlewares)) {
			const middlewaresPassed = await this.execMiddlewares(response, queryMiddlewares)
			if (!middlewaresPassed) {
				throw new Error(`The request to "${route}" failed due to middleware constraints.`)
			}
		}

		return response
	}

	exec<K extends RequestKey>(
		name: K,
		data: RequestVarBucket<K> = {},
	): Promise<RequestReturnTypes[K]> {
		const action = this._actions.get(name)

		if (!action) {
			throw new Error(`Action with name ${name} not found`)
		}

		return action(data) as Promise<RequestReturnTypes[K]>
	}
}
