<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useGalleryStore } from '@/stores/galleryStore.ts'
import { useSettingStore } from '@/stores/settingStore'
import { useAuthStore } from '@/stores/authStore'
import { EImageGalleryType } from '@/types'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useResizeListener } from '@/composables/useResizeListener'
import { useAutoScroll } from '@/composables/useAutoScroll'
import { useSearchStore } from '@/stores/searchStoreV2'
import { useCompatibilityStore } from '@/stores/compatibilityStore'
import router from '@/router'

const Image = defineAsyncComponent(() => import('@/components/image/Image.vue'))
const SearchPanel = defineAsyncComponent(() => import('@/components/search/SearchPanel.vue'))
const SpinnerHeart = defineAsyncComponent(() => import('@/components/SpinnerHeart.vue'))

const { t } = useI18n()
const route = useRoute()
const { stop: stopAutoScroll, setTarget } = useAutoScroll()
const settingStore = useSettingStore()
const compat = useCompatibilityStore()
const imageStore = useGalleryStore()
const { disable } = useScrollDirectionEvent()
const searchStore = useSearchStore()
const { openPanel } = storeToRefs(searchStore)
const { mobileDevice } = storeToRefs(compat)
const { computedScreenWidth } = useResizeListener()

const authStore = useAuthStore()

const intersectEnabled = ref<boolean>(false)
const gallery = ref<HTMLElement>()
const uuid = ref<string>('')

const { imageFullScreen, galleryScroll, restoreScroll, galleryType } = storeToRefs(settingStore)
const { user } = storeToRefs(authStore)
const { images, index: imageIndex } = storeToRefs(imageStore)

function zoomImage(index: number) {
	imageStore.setZoomedImage(index)
	imageFullScreen.value = true
	router.push({ path: `/image/${images.value[index].uuid}`, query: { ...route.query } })
}

function saveScroll(): void {
	if (gallery.value instanceof HTMLElement) {
		galleryScroll.value = openPanel.value ? gallery.value.scrollTop : window.scrollY
	}
}

restoreScroll.value = () => {
	nextTick(() => {
		setTimeout(() => {
			if (gallery.value instanceof HTMLElement) {
				(openPanel.value && !mobileDevice.value ? gallery.value : window).scrollTo({ top: galleryScroll.value })
				setTarget(openPanel.value ? gallery.value : window)
				intersectEnabled.value = true
			}
		}, 80)
	})
}

watch(
	() => imageFullScreen.value,
	(newVal) => {
		if (newVal && EImageGalleryType.Gallery === galleryType.value) {
			uuid.value = images.value[imageIndex.value].uuid
			saveScroll()
		}
	},
)

function popstate() {
	imageFullScreen.value = false
	// restoreScroll.value()
}

window.addEventListener('popstate', popstate)

onBeforeUnmount(() => {
	window.removeEventListener('popstate', popstate)
	saveScroll()
})

onMounted(async () => {
	await nextTick()
	setTimeout(() => {
		intersectEnabled.value = true
	}, 500)
})

watch(() => openPanel.value, async () => {
	saveScroll()
	if (computedScreenWidth.value >= 1024 || mobileDevice.value) {
		disable.value = true
		const windowScroll = window.scrollY
		const containerScroll = gallery.value instanceof HTMLElement ? gallery.value.scrollTop : 0
		await nextTick()

		if (gallery.value instanceof HTMLElement) {
			if (windowScroll) {
				gallery.value.scrollTo({ top: windowScroll })
			}
			else {
				window.scrollTo({ top: containerScroll })
			}
		}
		setTimeout(() => {
			disable.value = false
		}, 0)
	}
})

onBeforeRouteLeave(async () => {
	if (!imageFullScreen.value) {
		stopAutoScroll()
	}
})

watch(() => openPanel.value, async () => {
	if (openPanel.value) {
		setTarget(gallery.value as HTMLElement)
	}
	else {
		setTarget(window)
	}
})
</script>

<template>
	<div class="mt-[50px] w-full flex justify-center h-[calc(100dvh-60px)]">
		<div ref="gallery" style="-webkit-overflow-scrolling: touch;" class="w-full flex flex-wrap justify-center relative gap-1 pb-[100px]" :class="computedScreenWidth >= 1024 && openPanel ? 'overflow-hidden overflow-y-auto' : ''">
			<template v-for="(image, index) in images" :key="image.uuid">
				<Image :interactable="true" :data="images[index]" :index="image.uuid" @on-image-zoom="zoomImage(index)" />
			</template>
			<div v-if="images && !images.length && !useSearchStore().imagesFound" class="text-2xl font-semibold w-screen h-[calc(100vh-60px)] flex-center absolute">
				{{ t('common.no_results') }}
			</div>
			<SpinnerHeart v-else-if="!images.length" />

			<div v-if="intersectEnabled" v-intersect="imageStore.loadMoreImages" class="w-full h-[10px] mt-[-250px] -z-[1]" />
		</div>
		<SearchPanel />
	</div>
</template>

<style scoped>
/* General Scrollbar Style */
.scroll-container {
  scrollbar-width: auto; /* For Firefox */
  scrollbar-color: rgb(107, 33, 168) transparent; /* For Firefox */
}

/* Webkit-based browsers (Chrome, Edge, Safari) */
.scroll-container::-webkit-scrollbar {
  width: 16px; /* Width for vertical scrollbar */
  height: 16px; /* Height for horizontal scrollbar */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgb(107, 33, 168); /* Scrollbar color */
  border-radius: 4px; /* Rounded edges */
}

.scroll-container::-webkit-scrollbar-track {
  background: transparent; /* Track color */
}
</style>
