import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import type { INotification, INotificationContainer } from '@/types'

const getNotifications: ApiRequest<INotificationContainer> = {
	action: RequestAction.GetNotifications,
	endpoint: '?page={{page}}&size={{size}}&scopes={{scopes|join(,)}}',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		const data = await response.json()
		if (data) {
			data.items.forEach((notif: INotification<string | Date>) => {
				notif.created_at = new Date(notif.created_at as string)
			})
		}

		const container: INotificationContainer = {
			notifs: data.items,
		}

		if (response.headers.get('x-total-new-notifications')) {
			container.newNotifs = Number(response.headers.get('x-total-new-notifications'))
			container.newRewards = Number(response.headers.get('x-total-new-rewards'))
		}

		return container
	},
}

const consumeNotification: ApiRequest = {
	action: RequestAction.ConsumeNotification,
	endpoint: '{{id}}/consume',
	config: {
		method: 'PUT',
	},
}

const consumeAllNotification: ApiRequest = {
	action: RequestAction.ConsumeAllNotifications,
	endpoint: 'consume/all/{{notifType}}',
	config: {
		method: 'PUT',
	},
}

export default {
	prefix: '/api/notifications',
	middlewares: [],
	queries: [getNotifications, consumeNotification, consumeAllNotification],
} as RequestRoutes
