import setCloudflareTokenMiddleware from '../middlewares/setCloudflareToken'
import { type ApiRequest, RequestAction, type RequestRoutes } from '@/request/requestRouter'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'
import checkNotificationMiddleware from '@/request/middlewares/notification'
import type { BrowsePrompt, IPage } from '@/types'

const promptSave: ApiRequest<number> = {
	action: RequestAction.PromptSave,
	endpoint: 'save',
	config: {
		method: 'POST',
		credentials: 'include',
	},
	afterRequest: async (response: Response): Promise<number> => {
		const data = await response.json()
		return data.id
	},
}
const promptDelete: ApiRequest = {
	action: RequestAction.PromptDelete,
	endpoint: '{{id}}',
	config: {
		method: 'DELETE',
		credentials: 'include',
	},
}

const getBrowsePrompts: ApiRequest<IPage<BrowsePrompt>> = {
	action: RequestAction.GetBrowsePrompts,
	endpoint: '{{target}}?page={{page}}&size={{size=5}}',
	config: {
		method: 'POST',
		credentials: 'include',
	},
	afterRequest: async (response: Response): Promise<IPage<BrowsePrompt>> => {
		return await response.json()
	},
}

const subscribePrompt: ApiRequest = {
	action: RequestAction.SubscribePrompt,
	endpoint: '{{id}}/subscribe',
	config: {
		method: 'POST',
		credentials: 'include',
	},
}

const publishPrompt: ApiRequest = {
	action: RequestAction.PromptPublish,
	endpoint: '{{id}}/publish',
	config: {
		method: 'POST',
		credentials: 'include',
	},
}

export default {
	prefix: '/api/prompt',
	middlewares: [/* setCloudflareTokenMiddleware,  */disconnectUserMiddleware, checkNotificationMiddleware],
	queries: [promptSave, getBrowsePrompts, promptDelete, publishPrompt, subscribePrompt],
} as RequestRoutes
